import React from 'react';
import { Checkbox } from '@material-ui/core';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';

function MuteCheckbox(props, ref) {
  const handleChange = (event) => {
    props.onMuted(event.target.checked);
  };

  return (
    <Checkbox
      ref={ref}
      onChange={handleChange}
      icon={<Mic />}
      checkedIcon={<MicOff />}
      checked={!props.audioOn}
      disabled={props.disabled}
    />
  );
}

export default React.forwardRef(MuteCheckbox);
