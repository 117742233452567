import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
} from '@material-ui/core';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Episode from './script/Episode';

import { MeetingRoomShape } from './shapes';
import useUserType from './useUserType';

import './MeetingScript.scss';

const styles = () => ({
  root: {
    overflow: 'auto',
    maxHeight: '100%',
    // fontFamily: 'Poppins',
  },
  rootSm: {
  },
  logo: {
    zIndex: '25',
    maxWidth: '128px',
    maxHeight: '128px',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
    },
  },
  scriptSection: {
    whiteSpace: 'break-spaces',
  },
  scriptTitle: {
    fontSize: 24,
    fontWeight: 500,
    color: '#4A3DE4',
  },
  scriptSectionTitle: {
    color: '#0745C9',
    fontWeight: 500,
    fontSize: 18,
    marginBottom: 20,
  },
  scriptCard: {
    fontSize: 14,
    borderLeft: 'solid thin #42d3e2',
    boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.25)',
    marginBottom: 15,
    padding: 10,
  },
});

const useStyles = makeStyles(styles);

function MeetingScript({ roomEntity, canRecord }) {
  const classes = useStyles();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const rootClass = isSmallScreen ? classes.rootSm : classes.root;

  const [ userType ] = useUserType(roomEntity);

  const showCardRecordings = roomEntity?.script?.cardRecording ?? roomEntity?.cardRecording;

  return roomEntity ? (
    <Box className='lc-meeting-script'>
      {roomEntity.script ? (
        <Paper className={rootClass} elevation={3}>
          <Episode 
            roomId={roomEntity.roomId}
            script={roomEntity.script} 
            showCardRecordings={showCardRecordings}
            recordings={roomEntity.recordings}
            canRecord={canRecord}
            readOnly={userType !== 'host'}
          />
        </Paper>
      ) : (
        <Paper className={rootClass} elevation={3}>
          <Box p={2} mt={4}>
            <h5>Tips#1</h5>
            <p>Use a good microphone with your computer, your headset or your smartphone.</p>
            <h5>Tips#2</h5>
            <p>
              Stay close to your router to get the best connection possible.
              A Wired connection is always best.
            </p>
            <h5>Tips#3</h5>
            <p>Be in a room without echo: near curtains, or even in your closet.</p>

            <h5>Features</h5>
            <ul>
              <li>Record the call and download recordings.</li>
              <li>Mute (or eject) participant (for everyone and the recording).</li>
              <li>Select your microphone.</li>
              <li>Synchonize recordings with LiLiCAST.</li>
              <li>Hang up the call (or leave room) to reduce bandwith.</li>
            </ul>
          </Box>
        </Paper>
      )}
    </Box>
  ) : null;
}

MeetingScript.propTypes = {
  roomEntity: MeetingRoomShape.isRequired,
  canRecord: PropTypes.bool.isRequired,
};

export default MeetingScript;
