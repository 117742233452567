import PropTypes from 'prop-types';
import { Episode, EpisodeShape } from './script/shapes';

export const MeetingRoomRecordingShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  duration: PropTypes.number,
  startedOn: PropTypes.string,
  url: PropTypes.string,
  status: PropTypes.string,
});

export type MeetingRoomRecording = {
  id: string,
  duration?: number,
  startedOn?: string,
  url?: string,
  status?: string,
  cardId?: string,
};

export const MeetingRoomShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
  script: EpisodeShape,
  recordings: PropTypes.arrayOf(MeetingRoomRecordingShape).isRequired,
});

export type MeetingRoom = {
  id: string,
  roomId: string,
  roomName: string,
  cardRecording: boolean,
  deleted: boolean,
  script?: Episode,
  recordings: MeetingRoomRecording[],
};