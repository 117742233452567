import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { MdCall, MdCallEnd, MdExitToApp, MdSyncProblem } from 'react-icons/md';

import logoLilicast from '../../assets/logo-color.svg';

import './LiLiCallHeader.scss';

type LiLiCallHeaderProps = {
  isSelfDisconnected?: boolean,
  isRecording?: boolean,
  onCheckConnectivity?: () => void,
  onReconnect?: () => void,
  onDisconnect?: () => void,
  onLeaveRoom?: () => void,
};

function LiLiCallHeader({ 
  isSelfDisconnected, isRecording, 
  onCheckConnectivity, onReconnect, onDisconnect, onLeaveRoom,
}: LiLiCallHeaderProps) {

  return (
    <header className='lc-header'>
      <div className='lc-header-left'></div>
      <img className='lc-header-logo' src={logoLilicast} alt='LiLiCALL Logo' />
      <div className='lc-header-right'>
        {isSelfDisconnected ? (
          <IconButton color="inherit" onClick={onCheckConnectivity}>
            <Tooltip title="Test Connectivity">
              <MdSyncProblem />
            </Tooltip>
          </IconButton>
        ) : ''}

        {isSelfDisconnected ? (
          <IconButton color="inherit" onClick={onReconnect}>
            <Tooltip title="Reconnect.">
              <MdCall />
            </Tooltip>
          </IconButton>
        ) : ( isRecording ? '' : (
            <IconButton color="inherit" onClick={onDisconnect}>
              <Tooltip title="Disconnect (but stay on the page).">
                <MdCallEnd />
              </Tooltip>
            </IconButton>
          )
        )}
        <IconButton color="inherit" edge="end" onClick={onLeaveRoom}>
          <Tooltip title="Leave Room.">
            <MdExitToApp />
          </Tooltip>
        </IconButton>
      </div>
    </header>
  );
}

export default LiLiCallHeader;