import React, { useCallback, useMemo, useState } from 'react';

import isNil from 'lodash/isNil';
import { addSeconds, format } from 'date-fns';

import { 
  MdPause, MdPlayArrow,
} from 'react-icons/md';

import useAudioPlayer from './useAudioPlayer';
import { ClassesBuilder } from '../utils';

import RecordingProgressBar from './Episode.Edit.Recording.ProgressBar';

import './AudioPlayer.scss';

const formatDuration = (duration: number) => {
  if (isNil(duration)) return '--:--';

  const date = addSeconds(new Date(0), duration);
  return format(date, 'mm:ss');
};

type AudioInput = {
  url: string,
}

type AudioPlayerProps = {
  url: string,
  duration?: number,
  className?: string,
};

function AudioPlayer({ url, duration = 0, className }: AudioPlayerProps) {

  const audioInputs: AudioInput[] = useMemo(
    () => [ { url }],
    [ url ],
  );
  
  const hasAudio = useMemo(
    () => !!audioInputs && audioInputs.length > 0,
    [audioInputs],
  );

  const [ currentTime, setCurrentTime ] = useState(0);
  const onTimeUpdate = useCallback((currentTime) => {
    setCurrentTime(Math.floor(currentTime));
  }, []);

  const { 
    isPlaying, isInit, duration: audioDuration,
    play, pause, setFullAudioCurrentTime,
  } = useAudioPlayer(audioInputs, [], { onTimeUpdate });

  // Callbacks

  const classes = new ClassesBuilder('lc-audio-player', className);
  classes.addClassIf('has-audio', hasAudio);

  return (
    <div className={classes.toClassName()}>
      {hasAudio && (
        <RecordingProgressBar 
          current={currentTime} total={duration} 
          onClick={setFullAudioCurrentTime}
        />
      )}

      <div className='lc-episode-recording-content'>
        <div className='lc-episode-recording-controls'>
          {hasAudio && (
            isPlaying ? (
              <MdPause onClick={pause} />
            ) : (
              <MdPlayArrow onClick={play} />
            )
          )}
        </div>

        <div className='lc-episode-recording-main'>
          <span>
            {isInit && `${formatDuration(currentTime)} / `}{formatDuration(isInit ? audioDuration : duration)}
          </span>
        </div>
        <div>&nbsp;</div>
      </div>
    </div>
  );
}

export default AudioPlayer;
