import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import { ClassesBuilder } from './utils';

import './OutlineButton.scss';

type OutlineButtonProps = {
  id?: string;
  onClick?: () => void; 
  onClickDisabled?: () => void;
  onMouseDown?: () => void;
  primary?: boolean;
  disabled?: boolean;
  label: string;
  hideLabel?: boolean;
  icon?: ReactNode;
  className?: string;
  color?: string;
  size?: string;
  style?: React.CSSProperties;
  onContextMenu?: () => void;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onTouchStart?: () => void;
  type?: 'submit' | 'reset' | 'button';
  position?: string;
  badge?: string | number;
  uploadProgress?: number;
};

const OutlineButton = ({
  id,
  onClick, onClickDisabled,
  onMouseDown=()=>{},
  primary,
  disabled,
  label,
  hideLabel,
  icon,
  className,
  color,
  size,
  style,
  onContextMenu,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  onTouchStart,
  type,
  position,
  badge,
  uploadProgress,
  ...otherProps
}: OutlineButtonProps) => {
  const hideLabelClass = hideLabel ? `lc-hide-label-${hideLabel}` : '';

  const classes = new ClassesBuilder('lc-Outline-button', className);
  classes.addClassIf(color, !!color);
  classes.addClassIf(size, !!size);
  classes.addClassIf(hideLabelClass, !!hideLabelClass);
  classes.addClassIf('primary', !!primary);
  classes.addClassIf('disabled', !!disabled);

  const handleClick = disabled ? onClickDisabled : onClick;

  return (
    <button
      id={id}
      type={type}
      className={classes.toClassName()}
      style={ { marginLeft: position==='right' ? 'auto' : '', ...style } }
      onFocus={ onFocus }
      onMouseDown={ onMouseDown }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
      onContextMenu={ onContextMenu }
      onTouchStart={ onTouchStart }
      onClick={ handleClick }
      {...otherProps}
    >
      {uploadProgress ? (
        <div className='lc-outline-button-progress-container'>
          <div className='lc-outline-button-progress' style={{ width: `${uploadProgress}%`}} />
        </div>
      ) : null}
      {icon && (
        <span className='lc-Outline-button-icon'>{icon}</span>
      )}
      <span className='lc-Outline-button-label'>{label}</span>
      {badge ? (
        <div className='lc-outline-button-badge'>{badge}</div>
      ) : null}
    </button>
  );
};

OutlineButton.propTypes = {
  primary:         PropTypes.bool,
  disabled:        PropTypes.bool,
  label:           PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  hideLabel:       PropTypes.string,
  className:       PropTypes.string,
  color:           PropTypes.oneOf(['red', 'blue', 'light-blue', 'lighter-blue', 'white', 'grey', 'light-grey' ]),
  size:            PropTypes.string,
  style:           PropTypes.object,
  outline:         PropTypes.bool,
  onClick:         PropTypes.func,
  onClickDisabled: PropTypes.func,
  onMouseDown:     PropTypes.func,
  onContextMenu:   PropTypes.func,
  onFocus:         PropTypes.func,
  onMouseEnter:    PropTypes.func,
  onMouseLeave:    PropTypes.func,
  onTouchStart:    PropTypes.func,
  icon:            PropTypes.object,
  id:              PropTypes.string,
  type:            PropTypes.string,
  position:        PropTypes.string,
  badge:           PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default OutlineButton;
