import React, {
  useCallback, useState, useEffect, useRef,
} from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';

const styles = () => ({
});

const useStyles = makeStyles(styles);

// CONFIG
const TIMER_TIMEOUT = (20 * 60); // in seconds
const TIMER_DISCONNECT_TIMEOUT = 60; // in seconds

function AutoDisconnectModal(props) {
  const timerRef = useRef(); // the global timer
  const ticTimerRef = useRef(); // the last countdown

  const [secondsLeft, setSecondsLeft] = useState(TIMER_DISCONNECT_TIMEOUT);
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const {
    trackTime,
    onTimeout,
  } = props;

  const disconnectNow = useCallback(() => {
    resetTicTimer();
    setOpen(false);
    onTimeout();
  }, [onTimeout]);

  const onOpenTic = useCallback((secondsLeft) => {
    setSecondsLeft(secondsLeft - 1); // required to show to the user!
    if (secondsLeft === 1) {
      disconnectNow();
    } else {
      ticTimerRef.current = setTimeout(
        () => onOpenTic(secondsLeft - 1),
        1000,
      );
    }
  }, [disconnectNow]);

  const showModal = useCallback(() => {
    console.log('AutoDisconnectModal opening modal ... timeout.');
    setOpen(true);
    onOpenTic(TIMER_DISCONNECT_TIMEOUT);
  }, [onOpenTic, setOpen]);

  const startTimer = useCallback(() => {
    timerRef.current = setTimeout(
      showModal,
      TIMER_TIMEOUT * 1000,
    );
  }, [showModal]);

  const resetTicTimer = () => {
    if (ticTimerRef.current) {
      console.log('Clearing disconnect timer.');
      clearTimeout(ticTimerRef.current);
      ticTimerRef.current = null;
    }
  };

  const resetTimer = () => {
    if (timerRef.current) {
      console.log('Clearing disconnect timer.');
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  useEffect(() => {
    if (!trackTime) {
      console.log('AutoDisconnectModal resetTimer cause no trackTime needed.');
      // if we should not track, then clear things if any.
      return resetTimer();
    }
    if (!timerRef.current) {
      console.log('AutoDisconnectModal startTimer for a new timer.');
      startTimer();
    } else {
      console.log('AutoDisconnectModal doing nothing ... timer is running');
      // we should track and we have a timer, so
      // leave it as it is.
    }
  }, [trackTime, startTimer]);

  const restartTimer = () => {
    console.log('restartTimer');
    resetTimer();
    startTimer();
  };

  const doContinue = () => {
    resetTicTimer();
    setOpen(false);
    restartTimer();
  };

  const onClose = () => {
    doContinue();
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="recordings-dialog-title"
    >

      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Disconnecting in:
        {' '}
        <b>{secondsLeft}</b>
        {' '}
        seconds.
      </DialogTitle>

      <DialogContent dividers>
        <p>
          In order to reduce bandwith, we are gently suggesting you to disconnect
          yourself when you are not actively communicating or recording on LiLiCALL.
        </p>
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          onClick={doContinue}
          variant="contained"
          className={classes.button}
          startIcon={<CallIcon />}
        >
          Keep Connected
        </Button>

        <Button
          onClick={disconnectNow}
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<CallEndIcon />}
        >
          Disconnect
        </Button>
      </DialogActions>

    </Dialog>
  );
}

export default AutoDisconnectModal;
