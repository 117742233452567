import React, { Component } from 'react';

import {connectivityGradeFct} from './ConnectivityButton'
import MeetingAttendeeCard from './MeetingAttendeeCard'
import OTSubscriber from '../OT/OTSubscriber'
import SubscriberMOS from '../OT/SubscriberMOS'
import RoomDecorator from '../../lib/RoomDecorator'
import AudioLevelAverager from '../../lib/AudioLevelAverager'

export default class Subscriber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      audioOn: true,
      streamName: "Unknown",
      audioLevel: 0,
      audioActive: false,
      subscriber: null
    };

    this.audioAverager = new AudioLevelAverager();

    this.otSubscriber = React.createRef();

    this.subscriberEvents = {
      connected: (event) => {
        console.log("Subscriber connected with name: " + event.target.stream.name);
        this.setState({streamName: event.target.stream.name});
      },
      audioLevelUpdated: (event) => {
        this.audioAverager.newLevel(event.audioLevel, (error, {shouldUpdate, active, level}) => {
          if(shouldUpdate){
            this.setState({audioActive: active});
          }
        })
      },
      error: (event) => {
        console.log("Subscriber - error");
        console.log(event);
      }
    };
  }

  // componentDidMount() {
  //   this.getSubscriber();
  // }

  getSubscriber = () => {
    if(this.state.subscriber) return;

    if (this.otSubscriber && this.otSubscriber.current.getSubscriber()) {
      this.setState({
        subscriber: this.otSubscriber.current.getSubscriber(),
        mosEstimator: SubscriberMOS(this.otSubscriber.current.getSubscriber())
      });
      // give it a second to get stats
      setTimeout(this.watchStats, 1000);
    } else {
      // try later
      setTimeout(this.getSubscriber, 10000);
    }
  }

  watchStats = () => {
    if(this.state.mosEstimator){
      let mos = this.state.mosEstimator.audioScore();
      let newMosGrade = connectivityGradeFct(mos);
      if(this.state.mosGrade !== newMosGrade){
        console.log("Subscriber#mosGrade changed. Grade: " + newMosGrade + ' mos: ' + mos);
        this.setState({mosGrade: newMosGrade})
      }
    }

    if(this.state.mosGrade !== 'great' && this.state.subscriber){
      this.state.subscriber.getStats((error, statsArray) => {
        console.log("Subscriber#getStats", statsArray);
      })
    }

    // iteration, components unmounts when destroyed
    setTimeout(this.watchStats, 5000);
  }

  setAudio = (audio) => this.setState({ audio });

  onError = (err) => this.setState({ error: `Failed to subscribe: ${err.message}` });

  // helper for common interface
  getUserName() {
    return this.state.streamName;
  }

  onMuted = (checked) => {
    // don't change the subscribing audio
    // but publish signal so the targetted Publishers turns himself off.
    // this.setState({ audioOn: !checked });

    this.props.onMuteChange(this.getUserName(), !checked);

    // Ideal altenative that doens't work.
    // this.state.subscriber.stream.connection.signal({
    //   type: "userMuted",
    //   data: ""
  }

  componentDidMount(){
    // FIXME: we don't have the email in the stream name ...
    // So it'd come from a signal ... except we conventionnaly add the email in the streamName
    // this.props.onMounted({name: this.getUserName()})
  }

  // when a remote's Publisher has muted himself, this should be reflected
  // on the Subscriber page.
  componentDidUpdate(prevProps, prevState){

    // Unlike publisher, the subscriber is mounted when a new connection is created.
    this.getSubscriber();

    if(this.props.audioStates.hasOwnProperty(this.getUserName())){
      const remoteAudioState = this.props.audioStates[this.getUserName()];
      if(remoteAudioState !== this.state.audioOn){
        console.log("Updating mute state of Subscriber from remote (from " + this.state.audioOn + " to " + remoteAudioState +").")
        this.setState({audioOn: remoteAudioState});
      }
    }
  }

  render() {
    // console.log("Rendering Subscriber: " + this.state.streamName);
    const { roomEntity } = this.props;
    const roomDeco = new RoomDecorator(roomEntity);
    const userType = roomEntity ? roomDeco.userType(this.props.userEmail) : ''

    return (
      <div>
        <MeetingAttendeeCard
          asPublisher={false}
          audioActive={this.state.audioActive}
          audioLevel={this.state.audioLevel}
          audioOn={this.state.audioOn}
          connectionError={this.state.error}
          connected={this.props.connected}
          onEject={this.props.onEject}
          onMuted={this.onMuted}
          userName={this.state.streamName}
          userType={userType}
          mosGrade={this.state.mosGrade}
        />

        {/* audioOn here is irrelevant cause it's stopped at the source via remote mute. */}
        <OTSubscriber
          properties={{
            subscribeToAudio: this.state.audioOn,
            subscribeToVideo: false,
            showControls: false,
            insertDefaultUI: false,
            targetElement: null
          }}
          onError={this.onError}
          retry={true}
          maxRetryAttempts={3}
          retryAttemptTimeout={2000}
          eventHandlers={this.subscriberEvents}
          ref={this.otSubscriber}
        />
      </div>
    );
  }
}
