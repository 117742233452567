import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Grid,
  InputAdornment,
  LinearProgress,
  Paper,
  SnackbarContent,
  Typography,
} from '@material-ui/core';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Email from '@material-ui/icons/Email';
import People from '@material-ui/icons/People';

import { makeStyles } from '@material-ui/core/styles';

import Copyright from '../Copyright';
import CustomInput from '../CustomInput';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const initialFormData = (name, email) => Object.freeze({
  name: name || '',
  email: email || '',
});

function JoinMeetingRoomSide(props) {
  const classes = useStyles();

  const { roomId, queryEmail, queryName, onSubmit } = props;

  const [formData, updateFormData] = useState(initialFormData(queryName, queryEmail));
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = useCallback((e) => {
    console.log('handleChange', e.target.name, e.target.value);
    updateFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    }));
  }, [updateFormData]);

  const handleSubmit = useCallback((e) => {
    e && e.preventDefault();
    setLoading(true);
    console.log(`Joining the room as ${formData.name} - ${formData.email}`);
    onSubmit(formData, (err, success) => {
      setLoading(false);
      if (err) {
        console.log('Could not join the room.');
        console.log(err);
        setErrorMessage(`Could not open session for room \`${roomId}\`. ${err}`);
      } else {
        setErrorMessage(null);
      }
    });
  }, [formData.name, formData.email, setLoading, onSubmit]);

  // auto fill form
  // there is an auto login in dev mode inside the MeetingRoomApp
  const formRef = useRef();

  useEffect(() => {
    if (typeof (queryEmail) === 'string' && queryEmail.length > 0) {
      updateFormData({
        email: queryEmail,
        name: queryName,
      });

      console.log(`auto join with: ${queryEmail} / ${queryName}`);
      setTimeout(handleSubmit(), 100);
    }
  }, [queryEmail, queryName, handleSubmit]);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Join &quot;
            {roomId}
            &quot;
          </Typography>
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={classes.form}
            noValidate
          >
            <CustomInput
              labelText="Your Name"
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                name: 'name',
                value: formData.name,
                onChange: handleChange,
                endAdornment: (
                  <InputAdornment position="end">
                    <People className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
              }}
            />
            <CustomInput
              labelText="Email..."
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: handleChange,
                type: 'email',
                name: 'email',
                value: formData.email,
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
              }}
            />

            { isLoading ? <LinearProgress />
              : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Join Room
                </Button>
              )}

            {
              errorMessage ? (
                <SnackbarContent
                  message={(
                    <span>
                      <b>Error:</b>
                      {' '}
                      {errorMessage}
                    </span>
)}
                  close
                  color="danger"
                  icon="info_outline"
                />
              ) : null
}

            {
              /* This doesnt work here because we need a token ..
                 A solution would be to dummy join, get token for the test,
                then real join by the user.
              <ConnectivityTestModal
                open={openConnectivityTest}
                apiKey={props.apiKey}
                sessionId={props.sessionId}
                token={props.token}
              />

              <Divider />

              <Button color="inherit" onClick={() => openConnectivityTest()}}>
                <Tooltip title="Test Connectivity">
                  <SyncProblemIcon /> Test Connectivity
                </Tooltip>
              </Button>

              */
            }

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

JoinMeetingRoomSide.propTypes = {
  roomId: PropTypes.string.isRequired,
  queryName: PropTypes.string,
  queryEmail: PropTypes.string,
};

export default JoinMeetingRoomSide;
