import React from 'react';

export const RecordingContext = React.createContext(null);

const initialState = {
  isRecording: false,
  isFinalising: false,
  eventedRecordingId: null,
  cardId: undefined,
  error: undefined,
};

const SET_IS_RECORDING = 'RECORDING::SET_IS_RECORDING';
const SET_IS_FINALISING = 'RECORDING::SET_IS_FINALISING';
const START_RECORDING = 'RECORDING::START_RECORDING';
const STOP_RECORDING = 'RECORDING::STOP_RECORDING';
const SET_ERROR = 'RECORDING::SET_ERROR';

const SET_EVENTED_RECORDING_ID = 'RECORDING::SET_EVENTED_RECORDING_ID';

export const setIsRecording = (isRecording) => ({ type: SET_IS_RECORDING, isRecording });
export const setIsFinalising = (isFinalising) => ({ type: SET_IS_FINALISING, isFinalising });
export const startRecording = (cardId) => ({ type: START_RECORDING, cardId });
export const stopRecording = () => ({ type: STOP_RECORDING });
export const setError = (error) => ({ type: SET_ERROR, error });
export const setEventedRecordingId = (eventedRecordingId) => ({ type: SET_EVENTED_RECORDING_ID, eventedRecordingId });

function reducer(state, action) {
  switch(action.type) {
    case SET_IS_RECORDING:
      return { 
        ...state,
        isRecording: action.isRecording,
      };
    case SET_IS_FINALISING:
      return { 
        ...state,
        isFinalising: action.isFinalising,
      };
    case START_RECORDING:
      console.log('START_RECORDING', action);
      return { 
        ...state,
        isRecording: true,
        cardId: action.cardId,
      };
    case STOP_RECORDING:
      return { 
        ...state,
        isRecording: false,
        cardId: undefined,
      };

    case SET_EVENTED_RECORDING_ID:
      return {
        ...state,
        eventedRecordingId: action.eventedRecordingId,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }

}

export function RecordingContextProvider(props) {
  const [ state, dispatch ] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <RecordingContext.Provider value={value}>
    {props.children}
  </RecordingContext.Provider>
}
