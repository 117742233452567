import EventEmitter from 'events';
import isNil from 'lodash/isNil';

import { AudioSelection, RecordingSelections } from './types';

export const isInSelection = (timestamp = 0, selections: RecordingSelections[] = []) => !!getSelection(timestamp, selections);

export const getSelection = (timestamp = 0, selections: RecordingSelections[] = []) => {
  let offset = 0;
  for (const recordingSelections of selections) {
    const idx = getSelectionIdx(timestamp - offset, recordingSelections.selections);
    if (idx >= 0) return recordingSelections.selections[idx];

    offset += recordingSelections.selections.reduce((sum, { start, end }) => sum + (end - start), 0);
  }
  return null;
};

export const getSelectionIdx = (timestamp = 0, selections: AudioSelection[] = []) => {
  let idx = 0;
  for (const selection of selections) {
    if (timestamp >= selection.start && timestamp < selection.end) return idx;
    if (timestamp < selection.start) return -1;
    idx += 1;
  }
  return -1;
};

export const getNextSelectionIdx = (timestamp = 0, selections: RecordingSelections[] = [], durationOffsets: number[] = []) => {
  let audioIdx = -1;
  let selectionIdx = 0;
  for (const recordingSelections of selections) {
    audioIdx += 1;
    selectionIdx = 0;
    const ts = timestamp - (durationOffsets[audioIdx] ?? 0);
    for (const selection of recordingSelections.selections) {
      if (ts >= selection.start && ts < selection.end) return [audioIdx, selectionIdx];
      if (ts < selection.start) return [audioIdx, selectionIdx];
      selectionIdx += 1;
    }
  }
  return [audioIdx, -1];
};

export const getSelectionsDuration = (selections: AudioSelection[] = []) => selections.reduce((dur, selection) => {
  return dur + (selection.end - selection.start);
}, 0);

export const currentTimeToSelectionsTime = (currentTime = 0, selections: RecordingSelections[] = [], durationOffsets: number[] = []) => {
  let time = 0;
  let recordingIdx = 0;
  for (const recordingSelections of selections) {
    const offset = durationOffsets[recordingIdx] ?? 0;
    const current = currentTime - offset;
    // console.log('offset', offset);
    // console.log('current', current);
    // console.log('time', time);
    for (const selection of recordingSelections.selections) {
      if (current < selection.start) return time;
      if (current < selection.end) return time + current - selection.start;
        
      time += selection.end - selection.start;
    }
    recordingIdx += 1;
  } 
  return time;
};

export const selectionsTimeToFullAudioCurrentTime = (time = 0, selections: AudioSelection[] = []) => {
  let remaining = time;
  let idx = 0;
  while (remaining >= 0 && idx < selections.length) {
    const selection = selections[idx];
    const length = selection.end - selection.start;
    if (remaining < length) {
      return selection.start + remaining;
    }
    remaining -= length;
    idx += 1;
  }
  return selections.length > 0 ? selections[selections.length - 1].end : 0;
};

export type AudioInput = {
  url: string,
  duration?: number,
};

class AudioPlayerControllerMulti {
  inputs: AudioInput[];
  selections: RecordingSelections[];
  onlySelections: boolean;
  
  defaultVolume: number = 1;
  currentAudioIdx: number = 0;
  currentSelectionIdx: number = 0;
  isPlaying = false;
  emitter = new EventEmitter();
  
  loadedMetadata = false;
  audios: HTMLAudioElement[] = [];
  audioListeners: Record<string, (evt: Event) => void>[] = [];
  durations: number[] = [];

  selectionsDuration = 0;
  fullAudioDuration = 0;
  durationOffsets: number[] = [];

  playbackRate = 1;
  
  playInterval?: NodeJS.Timer;
  currentVolume?: number;

  constructor(inputs: AudioInput[], selections: RecordingSelections[] = [], onlySelections = false) {
    this.inputs = inputs;
    this.selections = selections;
    this.onlySelections = onlySelections;
    this.fullAudioDuration = inputs.reduce((dur, { duration }) => dur + (duration ?? 0), 0);
    this.initAudioPlayers();
    this.computeDuration(inputs.map(input => input.duration ?? 0));
    this.selectionsChanged();
  }

  protected computeDuration(durations: number[]) {
    this.fullAudioDuration = durations.reduce((dur, duration) => dur + (duration ?? 0), 0);
    this.durationOffsets = durations.reduce((offsets: number[], duration: number, idx: number) => {
      const prevOffset = offsets[idx];
      offsets.push(prevOffset + duration);
      return offsets;
    }, [0]);
  }

  protected selectionsChanged() {
    this.selectionsDuration = this.selections.reduce((dur, { selections }) => dur + getSelectionsDuration(selections), 0);
  }

  protected initAudioPlayers() {
    let loaded = 0;
    this.durations = [];

    this.audioListeners = this.inputs.map(() => ({}));
    this.audios = this.inputs.map(({url}, idx) => { 
      const audio = new Audio(url);
      const playListener = this.onAudioPlay(idx);
      const pauseListener = this.onAudioPause(idx);
      const endedListener = this.onAudioEnded(idx);
      const loadedmetadataListener = () => {
        this.durations[idx] = audio.duration;
        loaded += 1;
        // console.log(`loaded ${loaded} audio metadata`, audio.duration);
        if (loaded === this.inputs.length) {
          this.loadedMetadata = true;
          this.computeDuration(this.durations);
          this.emitter.emit('loadedmetadata');
        }
      };

      audio.addEventListener('play', playListener);
      this.audioListeners[idx]['play'] = playListener;

      audio.addEventListener('pause', pauseListener);
      this.audioListeners[idx]['pause'] = pauseListener;
      
      audio.addEventListener('ended', endedListener);
      this.audioListeners[idx]['ended'] = endedListener;
      
      audio.addEventListener('loadedmetadata', loadedmetadataListener);
      this.audioListeners[idx]['loadedmetadata'] = loadedmetadataListener;

      return audio;
    });

    this.addEventListener('play', this.startPlayInterval);
    this.addEventListener('pause', this.stopPlayInterval);
  }

  onAudioPlay = (idx: number) => () => {
    // console.log('onAudioPlay', idx, this.inputs[idx]);
    this.isPlaying = true;
    this.emitter.emit('play');
  }
  
  onAudioPause = (idx: number) => (evt: Event) => {
    // console.log('onAudioPause', idx, this.inputs[idx], evt.target);
    const audio = evt.target as HTMLAudioElement;
    if (this.isPlaying && audio.duration !== audio.currentTime) {
      this.isPlaying = false;
      this.emitter.emit('pause');
    }
  }
  
  onAudioEnded = (idx: number) => () => {
    // console.log('onAudioEnded', idx, this.inputs[idx], this.isPlaying);
    if (this.isPlaying) {
      if (idx === this.audios.length - 1) {
        // console.log('onAudioEnded last audio');
        this.emitter.emit('pause');
        this.emitter.emit('ended');
        this.currentAudioIdx = 0;
        this.setAudioPlayerCurrentTime(0, 0);
      } else {
        // console.log('onAudioEnded go to next audio');
        if (this.onlySelections) {
          this.jumpToNextSelection();
        } else {
          this.playAudio(this.currentAudioIdx + 1, 0);
        }
      }
    }
  }

  // Dev note - re calculating this every time might not be the most efficient
  get currentSelectionsTime() {
    return currentTimeToSelectionsTime(this.fullAudioCurrentTime, this.selections, this.durationOffsets);
  }

  get fullAudioCurrentTime() {
    const offset = this.durationOffsets[this.currentAudioIdx] ?? 0;
    return offset + (this.getAudioPlayer()?.currentTime ?? 0);
  }

  get currentTime() {
    return this.onlySelections 
      ? this.currentSelectionsTime 
      : this.fullAudioCurrentTime;
  }

  get paused() {
    const audio = this.getAudioPlayer();
    return audio?.paused ?? false;
  }

  get duration() {
    return this.onlySelections ? 
      this.selectionsDuration : 
      this.fullAudioDuration;
  }

  setSelections(selections: RecordingSelections[]) {
    this.selections = selections;
    this.selectionsChanged();
  }

  setOnlySelections(onlySelections: boolean) {
    this.onlySelections = onlySelections;
  }

  setCurrentTime(time: number) {
    console.warn('setCurrrentTime - TODO convert to full audio current time', time);
    const fullAudioCurrentTime = time;

    this.setFullAudioCurrentTime(fullAudioCurrentTime);
  }
    
  setFullAudioCurrentTime(time: number) {
    // console.log('setFullAudioCurrentTime', time);
    
    let newAudioIdx = -1;
    while ((newAudioIdx + 1) < this.durationOffsets.length && time > this.durationOffsets[newAudioIdx+1]) {
      newAudioIdx += 1;
    }
    // console.log('newAudioIdx', newAudioIdx);
    
    const audioCurrentTime = time - this.durationOffsets[newAudioIdx];
    // console.log('audioCurrentTime', audioCurrentTime);

    if (this.audios[newAudioIdx]) {
      this.playAudio(newAudioIdx, audioCurrentTime);
    }
    // if (this.audio) {
    //   // console.log('setFullAudioCurrentTime', time);
    //   this.audio.currentTime = time;
    // }
  }

  setPlayBackRate(rate: number) {
    this.playbackRate = rate;
    this.audios.forEach(audio => audio.playbackRate = rate);
    this.emitter.emit('ratechange', { playbackRate: rate });
  }

  protected _setVolume(volume?: number) {
    const newVolume = volume ?? this.defaultVolume;
    if (newVolume !== this.currentVolume) {
      this.currentVolume = newVolume;
      this.audios.forEach(audio => audio.volume = newVolume);
      this.emitter.emit('volumechange', { volume: newVolume });
    }
  }

  setVolume(volume: number) {
    this.defaultVolume = volume;
    this._setVolume(volume);
  }

  jumpToNextSelection(resetOnEnd = false) {
    if (!this.audios || !this.onlySelections) return;
    
    const currentAudioCurrentTime = this.getAudioPlayer()?.currentTime ?? 0;
    const fullAudioCurrentTime = this.fullAudioCurrentTime;
    // console.log('jumpToNextSelection', fullAudioCurrentTime, currentAudioCurrentTime);
    // console.log('current is', this.currentAudioIdx, this.currentSelectionIdx);
    const [ nextAudioIdx, nextSelectionIdx ] = getNextSelectionIdx(fullAudioCurrentTime, this.selections, this.durationOffsets);
    // console.log('next is', nextAudioIdx, nextSelectionIdx);

    if (nextSelectionIdx < 0) {
      if (resetOnEnd) {
        this.currentAudioIdx = 0;
        this.currentSelectionIdx = 0;
        this.setAudioPlayerCurrentTime(0, 0);
        this.jumpToNextSelection();
      } else {
        this.pause();
      }
      return;
    }
    
    this.currentSelectionIdx = nextSelectionIdx;
    const nextSelection = this.getSelections(nextAudioIdx)[nextSelectionIdx];
    this._setVolume(nextSelection.volume);

    if (nextAudioIdx !== this.currentAudioIdx) {
      // console.log('jumpToNextSelection - next audio', nextSelection.start);
      this.playAudio(nextAudioIdx, nextSelection.start);
    } else if (currentAudioCurrentTime < nextSelection.start) {
      // console.log('jumpToNextSelection - same audio, next selection', nextSelection.start);
      this.setAudioPlayerCurrentTime(nextAudioIdx, nextSelection.start);
    }
  }

  protected isLastAudio(idx = this.currentAudioIdx): boolean {
    return idx >= this.audios.length - 1;
  }

  protected getAudioPlayer(idx = this.currentAudioIdx): HTMLAudioElement | undefined {
    return this.audios[idx];
  }

  protected setAudioPlayerCurrentTime(idx: number, time?: number) {
    if (isNil(time)) return;
    const audio = this.getAudioPlayer(idx);
    if (audio) audio.currentTime = time;
  }

  protected getDurationOffset(idx = this.currentAudioIdx): number {
    return this.durationOffsets[idx] ?? 0;
  }

  protected getSelections(idx = this.currentAudioIdx): AudioSelection[] {
    return this.selections[idx]?.selections ?? [];
  }

  playAudio(idx: number, currentTime?: number) {
    // console.log('playAudio', idx, currentTime);
    if (idx !== this.currentAudioIdx) {
      this.pauseAudioPlayer();
    }

    this.currentAudioIdx = idx;
    this.setAudioPlayerCurrentTime(idx, currentTime);
    const audio = this.getAudioPlayer();
    if (audio?.paused) {
      this.jumpToNextSelection(true);
      audio.play();
    }
  }

  play() {
    this.playAudio(this.currentAudioIdx);
  }

  stopPlayInterval = () => {
    if (this.playInterval) {
      // console.log('stopPlayInterval');
      clearInterval(this.playInterval);
    }
  }

  startPlayInterval = () => {
    this.stopPlayInterval();
    // console.log('startPlayInterval');
    this.playInterval = setInterval(() => {
      const currentTime = this.currentTime;
      // console.log('playInterval', currentTime);
      this.emitter.emit('timeupdate', { currentTime });
      if (this.onlySelections) {
        this.jumpToNextSelection();
      } else {
        const selection = getSelection(this.fullAudioCurrentTime, this.selections);
        this._setVolume(selection?.volume);
      }
    }, 20);
  }
  
  pause() {
    this.pauseAudioPlayer();
  }
  
  pauseAudioPlayer(idx = this.currentAudioIdx) {
    // console.log('pausing', idx);
    this.getAudioPlayer(idx)?.pause();
  }

  addEventListener(event: string | symbol, listener: { (): void; (): void; (...args: any[]): void; }) {
    this.emitter.addListener(event, listener);
  }

  destroy() {
    this.emitter.removeAllListeners();
    console.log('destroy - Removing listeners of all audio elements');
    this.audios.forEach((audio, idx) => {
      const listeners = this.audioListeners[idx];
      Object.keys(listeners).forEach((event) => audio.removeEventListener(event, listeners[event]));
      audio.src = '';
    });
  }

}

export default AudioPlayerControllerMulti;
