import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {useMutation } from '@apollo/client';

import { postLilicall } from '../../lib/ApiWrapper';
import { RecordingContext, setError, setIsFinalising, setIsRecording, startRecording, stopRecording } from './recordingContext';
import { SessionContext } from './sessionContext';
import { RoomRecordingsSync } from './room.queries';

export function useRecorder(cardId) {

  const { 
    state: { 
      isRecording,
      cardId: recordingCardId,
      eventedRecordingId,
    }, 
    dispatch,
  } = useContext(RecordingContext);

  const { roomId, sessionId } = useContext(SessionContext);

  const [isStarting, setStarting] = useState(false);
  const [isStopping, setStopping] = useState(false);
  const [recordingId, setRecordingId] = useState(null);

  const [ roomRecordingsSync ] = useMutation(RoomRecordingsSync, { variables: { roomId }});

  const isSelfRecording = useMemo(
    () => isRecording && cardId === recordingCardId,
    [isRecording, cardId, recordingCardId],
  );
  
  const isOtherRecording = useMemo(
    () => isRecording && cardId !== recordingCardId,
    [ isRecording, cardId, recordingCardId ],
  );

  const handleSetIsRecording = useCallback(
    (isRec) => dispatch(setIsRecording(isRec)),
    [],
  );

  const handleError = useCallback((error) => {
    const errorMsg = error.error ? error.error : error;
    console.log(errorMsg);
    // fix inconsistency but it should not happen with the evented recordingId
    if (typeof (errorMsg) === 'string' && errorMsg.match(/Recording already in progress/)) {
      console.log('Assuming still recording from startRecord error message.');
      dispatch(startRecording(cardId));
    } else {
      console.error('recording error', errorMsg);
      dispatch(setError(errorMsg));
    }
  }, [handleSetIsRecording]);

  const handleStartRecording = useCallback(async () => {
    console.log('handleStartRecording', { isStarting, isStopping, isRecording });
    if (isStarting || isStopping || isRecording) return;

    try {
      setStarting(true);
      console.log('request start recording', roomId, sessionId);
      const result = await postLilicall(`/room/${roomId}/record/start`, JSON.stringify({ sessionId, cardId }));
      console.log('start recording result', result);
      if (result.error) return handleError(result.error);
      
      dispatch(startRecording(cardId));
      setRecordingId(result.id);
    } catch (err) {
      handleError(err);
    } finally {
      setStarting(false);
    }
  }, [ isRecording, isStarting, isStopping, roomId, sessionId, handleError, setRecordingId ]);

  const handleCheckForRecordingAvailable = useCallback(async (checkedFor = 0) => {
    if (checkedFor >= 60) {
      console.warn('Waited for 60s, but didnt receive the audio for recording', recordingId);
      dispatch(setIsFinalising(false));
      return;
    }
    
    dispatch(setIsFinalising(true));
    console.log('syncing recordings after', checkedFor);
    const { data: { syncRecordings: { recordings } = {} } = {} } = await roomRecordingsSync();
    const recording = recordings.find((rec) => rec.id === recordingId);
    if (recording && recording.url) {
      dispatch(setIsFinalising(false));
      return;
    }

    setTimeout(() => handleCheckForRecordingAvailable(checkedFor + 5), 5000);
  }, [ recordingId, roomRecordingsSync ]);

  const handleStopRecording = useCallback(async () => {
    if (!isRecording) return;

    try {
      setStopping(true);
      dispatch(setIsFinalising(true));
      console.log('request stop recording', roomId, recordingId);
      const result = await postLilicall(`/room/${roomId}/record/${recordingId}/stop`);
      console.log('stop recording result', result);
      dispatch(stopRecording());
      
      if (result.error) return handleError(result.error);

      handleCheckForRecordingAvailable();
    } catch (err) {
      handleError(err);
      dispatch(setIsFinalising(false));
    } finally {
      setStopping(false);
    }
  }, [isRecording, recordingId, roomId, handleError]);

  const toggleRecording = useCallback(async () => {
    if (isRecording) {
      await handleStopRecording();
    } else {
      await handleStartRecording();
    }
  }, [ isRecording, handleStartRecording, handleStopRecording ]);

  useEffect(() => {
    // console.log('eventedRecordingId changed', eventedRecordingId);
    if (isSelfRecording && !eventedRecordingId) {
      dispatch(stopRecording());
    }
  }, [eventedRecordingId]);

  return {
    isStarting, isStopping, isRecording, isSelfRecording, isOtherRecording, 
    startRecording: handleStartRecording,
    stopRecording: handleStopRecording,
    toggleRecording,
  };
}