export class ClassesBuilder {
  classes: string[];
  constructor(baseClass: string, className?: string) {
    this.classes = [baseClass];
    this.addClassIf(className ?? '', !!className);
  }

  clickable(bool = true): ClassesBuilder {
    if (bool) {
      this.classes.push('clickable');
    }
    return this;
  }

  addClass(className: string | undefined): ClassesBuilder {
    this.classes.push(className ?? '');
    return this;
  }

  addClassIf(className: string | undefined, condition: boolean): ClassesBuilder {
    if (condition) {
      return this.addClass(className);
    }
    return this;
  }

  toClassName(): string {
    return this.classes.join(' ');
  }
}

