import {  useCallback, useEffect, useRef, useState } from 'react';
import { RecordingSelections } from './types';

import AudioPlayerController, { AudioInput } from './AudioPlayerController.multi';

const DEFAULT_SELECTIONS: RecordingSelections[] = [];

type UseAudioPlayerOptions = { 
  onlySelections?: boolean, 
  onTimeUpdate?: (currentTime: number, fullAudioCurrentTime: number) => void,
};

function useAudioPlayer(
  inputs: AudioInput[], selections: RecordingSelections[] = DEFAULT_SELECTIONS, 
  { onlySelections = false, onTimeUpdate }: UseAudioPlayerOptions = {},
) {

  // State
  const [ isPlaying, setIsPlaying ] = useState(false);
  // const [ currentTime, setCurrentTime ] = useState(0);
  // const [ fullAudioCurrentTime, setFullAudioCurrentTime ] = useState(0);
  const [ duration, setDuration ] = useState(0);
  const [ fullAudioDuration, setFullAudioDuration ] = useState(0);
  const [ playBackRate, setPlayBackRate ] = useState(1);

  // Effect

  const controller = useRef<AudioPlayerController|undefined>();
  useEffect(function urlChanged() {
    // console.log('useAudioPlayer - inputs changed');
    if (controller.current) {
      controller.current.destroy();
      setIsPlaying(false);
      // setCurrentTime(0);
      setPlayBackRate(1);
    }
    controller.current = undefined;
  }, [ inputs ]);

  useEffect(function selectionsChanged() {
    // console.log('Selections changed', selections);
    if (controller.current) {
      controller.current.setSelections(selections);
      setDuration(controller.current.duration);
    }
  }, [ selections ]);

  useEffect(function onlySelectionsChanged() {
    // console.log('onlySelections changed', onlySelections);
    if (controller.current) {
      controller.current.setOnlySelections(onlySelections);
      setDuration(controller.current.duration);
    }
  }, [ onlySelections ]);

  useEffect(function onMount() {
    return function onUnmount() {
      if (controller.current) {
        controller.current.destroy();
      }
    };
  }, []);

  // Callbacks

  const onPlay = useCallback(() => setIsPlaying(true), [ setIsPlaying ]);
  const onPause = useCallback(() => setIsPlaying(false), [ setIsPlaying ]);
  const handleTimeUpdate = useCallback(() => {
    if (controller.current && onTimeUpdate) {
      onTimeUpdate(controller.current.currentTime, controller.current.fullAudioCurrentTime);
    }
  }, [onTimeUpdate]);
  const onPlayRateChange = useCallback(() => controller.current && setPlayBackRate(controller.current.playbackRate), [ controller, setPlayBackRate ]);
  const onLoadedMetadata = useCallback(() => {
    if (controller.current) {
      setDuration(controller.current.duration);
      setFullAudioDuration(controller.current.fullAudioDuration);
    }
  }, [ controller, setDuration, setFullAudioDuration ]);

  const initAudioPlayer = useCallback(() => {
    if (inputs && !controller.current) {
      console.log('initAudioPlayer', inputs);
      controller.current = new AudioPlayerController(inputs, selections, onlySelections);
      controller.current.addEventListener('play', onPlay);
      controller.current.addEventListener('pause', onPause);
      controller.current.addEventListener('timeupdate', handleTimeUpdate);
      controller.current.addEventListener('ratechange', onPlayRateChange);
      controller.current.addEventListener('loadedmetadata', onLoadedMetadata);
    }
  }, [inputs, selections, onlySelections, onPlay, onPause, handleTimeUpdate, onPlayRateChange, onLoadedMetadata]);
  
  const handlePlay = useCallback(() => {
    initAudioPlayer();
    if (controller.current) {
      controller.current.play();
    }
  }, [ controller, initAudioPlayer ]);
    
  const handlePause = useCallback(() => {
    if (controller.current) {
      controller.current.pause();
    }
  }, [ controller ]);

  const handleBack = (amount = 30) => {
    if (controller.current) {
      const currentTime = controller.current.currentTime;
      controller.current.setCurrentTime(Math.max(0, currentTime - amount));
    }
  };
  
  const handleForward = (amount = 30) => {
    if (controller.current) {
      const currentTime = controller.current.currentTime;
      controller.current.setCurrentTime(Math.min(controller.current.duration, currentTime + amount));
    }
  };

  const handlePlayBackRateChange = useCallback((rate) => {
    initAudioPlayer();
    if (controller.current) {
      controller.current.setPlayBackRate(rate);
    }
  }, [ controller, initAudioPlayer ]);

  const handleSeekAudio = useCallback((time) => {
    console.log('handleSeekAudio', time);
    initAudioPlayer();
    if (controller.current) {
      controller.current.setCurrentTime(time);
    }
  }, [ controller, initAudioPlayer ]);

  const handleSeekFullAudio = useCallback((time) => {
    // console.log('handleSeekFullAudio', time);
    initAudioPlayer();
    if (controller.current) {
      controller.current.setFullAudioCurrentTime(time);
    }
  }, [ controller, initAudioPlayer ]);

  const handleSetVolume = useCallback((volume) => {
    // console.log('handleSetVolume', volume);
    initAudioPlayer();
    if (controller.current) {
      controller.current.setVolume(volume);
    }
  }, [ controller, initAudioPlayer ]);

  const getCurrentTime = useCallback(
    () => controller.current && controller.current.currentTime,
    [],
  );

  const getFullAudioCurrentTime = useCallback(
    () => controller.current && controller.current.fullAudioCurrentTime,
    [],
  );

  const getDuration = useCallback(
    () => controller.current && controller.current.duration,
    [],
  );

  const getFullAudioDuration = useCallback(
    () => controller.current && controller.current.fullAudioCurrentTime,
    [],
  );

  const getPlaybackRate = useCallback(
    () => controller.current && controller.current.playbackRate,
    [],
  );

  return {
    isInit: !!controller.current,
    isPlaying, playBackRate,
    duration, 
    fullAudioDuration, 
    // currentTime,
    getCurrentTime,
    // fullAudioCurrentTime,
    getFullAudioCurrentTime,
    getDuration,
    getFullAudioDuration,
    getPlaybackRate,
    init: initAudioPlayer,
    play: handlePlay,
    pause: handlePause,
    back: handleBack,
    forward: handleForward,
    setPlayBackRate: handlePlayBackRateChange,
    setCurrentTime: handleSeekAudio,
    setFullAudioCurrentTime: handleSeekFullAudio,
    setVolume: handleSetVolume,
  };
}

export default useAudioPlayer;
