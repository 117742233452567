import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Select,
  MenuItem,
} from '@material-ui/core';

import OT from '@opentok/client';

function SelectAudioDevice({ audioDeviceChanged }) {
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [selectedDeviceId, setAudioInputId] = useState('');

  const handleChange = (event) => {
    setAudioInputId(event.target.value);
    audioDeviceChanged(event.target.value);
  };

  // run when the input is focused to reload the list.
  const fetchAudioDevices = () => {
    OT.getDevices((error, devices) => {
      if (error) {
        console.error('Error retrieving audio devices', error);
        return;
      }

      const audioDevices = devices.filter((device) => device.kind === 'audioInput');
      setAudioInputDevices(audioDevices);
      setAudioInputId(audioDevices[0].deviceId);
    });
  };

  const audioItems = audioInputDevices.map((dev) => (
    <MenuItem value={dev.deviceId} key={dev.deviceId}>{dev.label}</MenuItem>
  ));

  useEffect(() => {
    fetchAudioDevices();
  }, []);

  // run only on init
  // if(audioInputDevices.length === 0){
  //   fetchAudioDevices()
  // }

  return (
    <Select
      labelId="select-audio-device-label"
      id="select-audio-device"
      value={selectedDeviceId}
      onOpen={fetchAudioDevices}
      onChange={handleChange}
    >
      { audioItems }
    </Select>
  );
}

SelectAudioDevice.propTypes = {
  audioDeviceChanged: PropTypes.func.isRequired,
};

export default SelectAudioDevice;
