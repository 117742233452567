import React from 'react';
import PropTypes from 'prop-types';
import { IoChatbubblesOutline, IoMusicalNotesOutline, IoChatboxOutline } from 'react-icons/io5';

function EpisodeCardIcon({ cardType }) {
  switch (cardType) {
    case 'chat':
      return <IoChatbubblesOutline className="lc-script-card-icon" />;
    case 'music':
      return <IoMusicalNotesOutline className="lc-script-card-icon" />;
    case 'speech':
    default:
      return <IoChatboxOutline className="lc-script-card-icon" />;
  }
}

EpisodeCardIcon.propTypes = {
  cardType: PropTypes.string,
};

export default EpisodeCardIcon;
