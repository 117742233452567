export default class RoomDecorator {
  constructor(room) {
    this.room = room;
  }

  guestFromEmail(email) {
    return this.room.guests.find((user) => user.email === email);
  }

  userType(email) {
    if (this.guestFromEmail(email)) return 'guest';
    if (this.room.host && this.room.host.email === email) return 'host';
    return 'uninvited';
  }
}
