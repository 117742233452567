import React, { useCallback, useEffect, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NetworkTest from 'opentok-network-test-js';
import OT from '@opentok/client';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  LinearProgress,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1 / 2),
    top: theme.spacing(1 / 2),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles(styles);

function ConnectivityTestModal(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    open,
    apiKey,
    sessionId,
    token,
    onClose,
  } = props;

  const [testResults, setResults] = useState(null);
  const [loadingStep, setLoadingStep] = useState(10);

  const execNetworkTest = useCallback(() => {
    console.log('Executing Network Test ...');

    const otNetworkTest = new NetworkTest(OT, {
      apiKey,
      sessionId,
      token,
    }, {
      audioOnly: true,
    });

    otNetworkTest.testQuality((stats) => {
      const currentStats = stats[stats.length - 1];
      setLoadingStep(Math.min(loadingStep + 20, 100));
      console.log('NetworkTest: testQuality stats', currentStats);
    }).then((results) => {
      console.log('NetworkTest: OpenTok quality results', results.audio);
      setResults(results.audio);
    }).catch((error) => {
      console.log('NetworkTest: OpenTok quality test error', error);
    });
  }, [apiKey, loadingStep, sessionId, token]);

  useEffect(() => {
    if (props.open) {
      execNetworkTest();
    }
  }, [props.open, execNetworkTest]);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={onClose}
      open={open}
      aria-labelledby="recordings-dialog-title"
    >

      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Connectivity Test

        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        { testResults ? (
          <dl>
            <dt>Bitrate</dt>
            <dd>{testResults.bitrate}</dd>

            <dt>Packet Loss Ratio</dt>
            <dd>{testResults.packetLossRatio}</dd>

            <dt>MOS</dt>
            <dd>{testResults.mos}</dd>
          </dl>
        ) : (
          <LinearProgress variant="determinate" value={loadingStep} />
        )}

      </DialogContent>

    </Dialog>
  );
}

export default ConnectivityTestModal;
