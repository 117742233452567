import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
//       main: red.A400,
// secondary: {
//   main: '#f4524d',
//   contrastText: "white",
// },
// text: {
//   secondary: 'white',
// },
const theme = createTheme({
  palette: {
    primary: {
      main: '#4A3DE4',
      contrastText: 'white',
    },
    secondary: {
      main: '#f4524d',
    },
    error: {
      main: '#dc3545',
    },
    background: {
      default: '#fff',
    },
  },
  container: {
    zIndex: '12',
    color: '#FFFFFF',
  },
  typography: {
    allVariants: {
      fontFamily: '"Poppins", "Helvetica", sans-serif',
    },
    h2: {
      fontFamily: 'Helvetica',
      fontWeight: 500,
    },
  },
  title: {
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '10px auto 0',
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
    flexGrow: 1,
  },
  mainRaised: {
    margin: '90px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  itemGrid: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  description: {
    color: '#999',
  },
  recordingItems: {
    marginRight: '40px',
  },
  justifyCenter: {
    justifyContent: 'center !important',
  },
  attendeeCard: {
    width: '20rem',
  },
  cardDisconnected: {
    backgroundColor: 'light-red',
  },
  cardConnected: {
  },
});

export default theme;
