import PropTypes, { shape } from 'prop-types';

export const CardShape = shape({
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  type: PropTypes.oneOf(['chat', 'music', 'speech']),
  audioUrl: PropTypes.string,
  audioDuration: PropTypes.number,
});

export type Card = {
  id: string,
  text?: string,
  type: 'chat' | 'music' | 'speech',
  audioUrl?: string,
  audioDuration?: number,
};

export const SegmentShape = shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  cards: PropTypes.arrayOf(CardShape),
});

export type Segment = {
  id: string,
  title?: string,
  cards: Card[],
  text?: string,
};

export const EpisodeShape = shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  logoUrl: PropTypes.string,
  sections: PropTypes.arrayOf(SegmentShape),
});

export type Episode = {
  id: string,
  title?: string,
  logoUrl?: string,
  cardRecording: boolean,
  sections: Segment[],
}
