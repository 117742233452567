import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import once from 'lodash/once';
import isNil from 'lodash/fp/isNil';
import omitBy from 'lodash/fp/omitBy';
import uuid from 'uuid';
import OT  from '@opentok/client';

class OTPublisher extends Component {

  // static whyDidYouRender = true

  constructor(props, context) {
    super(props);

    this.state = {
      publisher: null,
      lastStreamId: '',
      session: props.session || context.session || null,
    };
  }

  componentDidMount() {
    this.createPublisher();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !isEqual(nextProps, this.props) ||
      !isEqual(nextState, this.state) ||
      !isEqual(nextContext  , this.context);
  }

  shouldUpdate(prevProps, key, defaultValue){
    const previous = this.useDefault(prevProps.properties[key], defaultValue);
    const current = this.useDefault(this.props.properties[key], defaultValue);
    return previous !== current;
  };

  updatePublisherProperty(prevProps, key, defaultValue){
    if (this.shouldUpdate(prevProps, key, defaultValue)) {
      const value = this.useDefault(this.props.properties[key], defaultValue);
      this.state.publisher[key](value);
    }
  };

  useDefault(value, defaultValue){
    return (value === undefined ? defaultValue : value)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldUpdate(prevProps, 'videoSource', undefined)) {
      this.destroyPublisher();
      this.createPublisher();
      return;
    }

    if (
      this.shouldUpdate(prevProps, 'audioSource', undefined) ||
      this.shouldUpdate(prevProps, 'disableAudioProcessing', false) ||
      this.shouldUpdate(prevProps, 'enableStereo', false) ||
      this.shouldUpdate(prevProps, 'audioBitrate', null)
      ) {
      console.log("Publisher properties changed; re-creating.", {
        audioSource: this.props.properties['audioSource'],
        disableAudioProcessing: this.props.properties['disableAudioProcessing'],
        enableStereo: this.props.properties['enableStereo'],
        audioBitrate: this.props.properties['audioBitrate'],
      })
      this.destroyPublisher();
      this.createPublisher();
      return;
    }

    this.updatePublisherProperty(prevProps, 'publishAudio', true);
    this.updatePublisherProperty(prevProps, 'publishVideo', false);

    if (this.state.session !== prevState.session) {
      this.destroyPublisher(prevState.session);
      this.createPublisher();
    }
  }

  componentWillUnmount() {
    if (this.state.session) {
      this.state.session.off('sessionConnected', this.sessionConnectedHandler);
    }

    this.destroyPublisher();
  }

  getPublisher() {
    return this.state.publisher;
  }

  destroyPublisher(session = this.state.session) {
    delete this.publisherId;

    if (this.state.publisher) {
      this.state.publisher.off('streamCreated', this.streamCreatedHandler);

      if (
        this.props.eventHandlers &&
        typeof this.props.eventHandlers === 'object'
      ) {
        this.state.publisher.once('destroyed', () => {
          this.state.publisher.off(this.props.eventHandlers);
        });
      }

      if (session) {
        session.unpublish(this.state.publisher);
      }
      this.state.publisher.destroy();
    }
  }

  publishToSession(publisher) {
    const { publisherId } = this;

    this.state.session.publish(publisher, (err) => {
      if (publisherId !== this.publisherId) {
        // Either this publisher has been recreated or the
        // component unmounted so don't invoke any callbacks
        return;
      }
      if (err) {
        this.errorHandler(err);
      } else if (typeof this.props.onPublish === 'function') {
        this.props.onPublish();
      }
    });
  }

  createPublisher() {
    if (!this.state.session) {
      this.setState({ publisher: null, lastStreamId: '' });
      return;
    }

    const properties = this.props.properties || {};
    let container;

    if (properties.insertDefaultUI !== false) {
      container = document.createElement('div');
      container.setAttribute('class', 'OTPublisherContainer');
      this.node.appendChild(container);
    }

    this.publisherId = uuid();
    const { publisherId } = this;

    this.errorHandler = once((err) => {
      if (publisherId !== this.publisherId) {
        // Either this publisher has been recreated or the
        // component unmounted so don't invoke any callbacks
        return;
      }
      if (typeof this.props.onError === 'function') {
        this.props.onError(err);
      }
    });

    // attempts on Safari ... none worked ...
    // but Safari asked for permission with relaxed constractin ...
    // but it never captured audio.

    // var properties = {
    //   audioBitrate: null,
    //   audioSource: "default",
    //   disableAudioProcessing: false,
    //   enableStereo: false,
    //   insertDefaultUI: false,
    //   name: "Diego",
    //   publishAudio: true,
    //   publishVideo: false,
    //   resolution: "1280x720",
    //   showControls: false,
    //   targetElement: null,
    //   videoSource: null
    // }
    // properties = {
    //   publishAudio: true,
    //   publishVideo: false,
    // }
    // publisher = OT.initPublisher(null, properties, (err) => {
    //   console.log("Cannot initPublisher: ", err);
    // })

    console.log('initPublisher with', properties);
    const publisher = OT.initPublisher(container, properties, (err) => {
      if (publisherId !== this.publisherId) {
        // Either this publisher has been recreated or the
        // component unmounted so don't invoke any callbacks
        return;
      }
      if (err) {
        this.errorHandler(err);
      } else if (typeof this.props.onInit === 'function') {
        this.props.onInit();
      }
    });
    publisher.on('streamCreated', this.streamCreatedHandler);

    if (
      this.props.eventHandlers &&
      typeof this.props.eventHandlers === 'object'
    ) {
      const handles = omitBy(isNil)(this.props.eventHandlers);
      publisher.on(handles);
    }

    if (this.state.session.connection) {
      this.publishToSession(publisher);
    } else {
      this.state.session.once('sessionConnected', this.sessionConnectedHandler);
    }

    this.setState({ publisher, lastStreamId: '' });
  }

  sessionConnectedHandler = () => {
    this.publishToSession(this.state.publisher);
  }

  streamCreatedHandler = (event) => {
    console.log("streamCreatedHandler ...")
    console.log(event)
    this.setState({ lastStreamId: event.stream.id });
    this.props.onStreamCreated(event.stream);
  }

  render() {
    const { className, style } = this.props;
    return <div className={className} style={style} ref={(node) => { this.node = node; }} />;
  }
}

OTPublisher.propTypes = {
  session: PropTypes.shape({
    connection: PropTypes.shape({
      connectionId: PropTypes.string,
    }),
    once: PropTypes.func,
    off: PropTypes.func,
    publish: PropTypes.func,
    unpublish: PropTypes.func,
  }),
  className: PropTypes.string,
  style: PropTypes.object,
  properties: PropTypes.object,
  eventHandlers: PropTypes.objectOf(PropTypes.func),
  onInit: PropTypes.func,
  onPublish: PropTypes.func,
  onError: PropTypes.func,
};

OTPublisher.defaultProps = {
  session: null,
  className: '',
  style: {},
  properties: {},
  eventHandlers: null,
  onInit: null,
  onPublish: null,
  onError: null,
};

OTPublisher.contextTypes = {
  session: PropTypes.shape({
    connection: PropTypes.shape({
      connectionId: PropTypes.string,
    }),
    once: PropTypes.func,
    off: PropTypes.func,
    publish: PropTypes.func,
    unpublish: PropTypes.func,
  }),
};

export default OTPublisher;