import React, { useMemo } from 'react';

import groupBy from 'lodash/groupBy';

import EpisodeCard from './Episode.Card';

import { Episode as EpisodeType } from './shapes';
import { MeetingRoomRecording } from '../shapes';

import './Episode.scss';

type EpisodeProps = {
  roomId: string,
  script: EpisodeType,
  recordings: MeetingRoomRecording[],
  showCardRecordings: boolean,
  canRecord: boolean,
  readOnly: boolean,
};

function Episode({ roomId, script, recordings, showCardRecordings, canRecord, readOnly }: EpisodeProps) {

  const cardRecordings = useMemo(
    () => groupBy(recordings, 'cardId'),
    [recordings],
  );

  return (
    <div className="lc-episode-script">
      { Array.isArray(script.sections) && script.sections.map((section, i) => (
        <div className="lc-script-section" key={`section-${i}`}>
          <h5 className="lc-script-section-title">{section.title}</h5>
          {section.cards
            ? section.cards.map((card, idx) => (
              <EpisodeCard 
                key={`card-${idx}`} 
                roomId={roomId}
                card={card} 
                showCardRecordings={showCardRecordings}
                recordings={cardRecordings[card.id] ?? []}
                canRecord={canRecord}
                readOnly={readOnly}
              />
            )) : (
              <p>{section.text}</p>
            )}
        </div>
      ))}
    </div>
  );
}

export default Episode;
