import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { MdClose } from 'react-icons/md';

import { MeetingRoomRecording } from '../shapes';
import RecordingList from './RecordingList';

import './Recordings.Dialog.scss';

type RecordingsDialogProps = {
  roomId: string,
  cardId?: string,
  recordings: MeetingRoomRecording[],
  open: boolean,
  onClose: () => void,
};

function RecordingsDialog(props: RecordingsDialogProps) {
  const { open, onClose } = props;

  return (
    <Dialog className='lc-recordings-dialog' open={open} onClose={onClose}>
      <DialogTitle className='lc-recordings-dialog-title-wrapper' disableTypography={true}>
        <span className='lc-recordings-dialog-title'>Recordings</span>

        <IconButton className='lc-recordings-dialog-close' aria-label="Close" onClick={onClose}>
          <MdClose />
        </IconButton>
      </DialogTitle>


      <DialogContent dividers>
        <RecordingList 
          {...props} 
          showAll={true} 
          showRecord={false} 
        />
      </DialogContent>
    </Dialog>
  );
}

export default RecordingsDialog;