import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { ClassesBuilder } from '../utils';

function RecordingProgressBar({ current, total, className, onClick }) {
  const classes = new ClassesBuilder('lc-episode-recording-progress-container', className);
  
  const progress = (total) ?
    (current*100) / total :
    0;

  const progressContainerRef = useRef();
  const handleClickProgress = useCallback((evt) => {
    if (!(total || onClick)) return;
    
    const clickX = evt.clientX;
    const { x, width } = progressContainerRef.current.getBoundingClientRect();
    const percent = (clickX - x) / width;
    onClick && onClick(percent * (total || 0));
  },  [ total, onClick ]);

  return (
    <div 
      ref={progressContainerRef}
      className={classes.toClassName()} 
      onClick={handleClickProgress}
    >
      <div 
        className='lc-episode-recording-progress' 
        style={{ width: `${progress}%` }} 
      />
    </div>
  );
}

RecordingProgressBar.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default RecordingProgressBar;
