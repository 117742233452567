import React, { useState } from 'react';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  Tooltip,
  Typography,
  SnackbarContent,
} from '@material-ui/core';

import SettingsApplications from '@material-ui/icons/SettingsApplications';
import Settings from '@material-ui/icons/Settings';

import ConnectivityButton from './ConnectivityButton';
import EjectButton from './EjectButton';
import MuteCheckbox from './MuteCheckbox';
import PublisherSettingsModal from './PublisherSettingsModal';

const useStyles = makeStyles(({ spacing, palette }) => ({
  btnGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& button': {
      borderRight: 'none !important',
      padding: `${spacing(1)}px 0px`,
    },
  },
  card: {
    display: 'flex',
    padding: spacing(2),
    paddingBottom: 0,
    minWidth: 200,
    borderRadius: 8,
    boxShadow: '0 2px 4px 0 rgba(138, 148, 159, 0.2)',
  },
  activeCard: {
    boxShadow: '0 0 4px 4px rgba(74,61,228, 0.3) !important',
  },
  avatar: {
    marginRight: 10,
  },
  heading: {
    fontSize: 12,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: palette.grey[600],
    letterSpacing: '1px',
    marginBottom: 4,
  },
  value: {
    marginLeft: 8,
    fontSize: 14,
    color: palette.grey[500],
  },
  grow: {
    flexGrow: 1,
  },
}));

function MeetingAttendeeCard(props) {
  const classes = useStyles();

  const {
    asPublisher,
    userName,
    userEmail,
    userType,
    connected,
    connectionError,
    onMuted,
    mosEstimate,
    onEject,
    disconnect,
    audioLevel,
    audioOn,
    canUnMute = true,
    audioActive,
    ...rest
  } = props;

  const asSubscriber = !asPublisher;

  // const [settingsOpen, setSettingsOpen] = useState(asPublisher);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const openSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  // could use the connected props
  // this is not actually used.
  const connectionNotice = props.connectionError ? (
    <SnackbarContent
      message={(
        <span>
          <b>Error:</b>
          {' '}
          {connectionError}
        </span>
)}
      icon="info_outline"
    />
  ) : '';

  // console.log("Rendering MeetingAttendeeCard asPublisher " + asPublisher);

  const styles = useStyles();
  const cardClass = audioActive ? classNames(styles.card, styles.activeCard) : styles.card;

  return (
    <>
      <Box mb={3}>
        <Card className={cardClass} elevation={2}>
          <Box display='flex' flexGrow={1} flexDirection='column' alignItems='flex-start'>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box>
                <Avatar className={styles.avatar} />
              </Box>

              <Box ml={1}>
                <Typography variant="caption">
                  {userType}
                </Typography>
                <Typography variant="subtitle2">
                  {userName}
                </Typography>
              </Box>
            </Box>

            <Box display="flex" flexGrow={1} flexDirection="row" alignItems="center" width='100%'>
              <ButtonGroup className={classes.btnGroup} variant="text" aria-label="small text button group">

                {/*
                  <Button disabled>
                    <AudioMeter audioLevel={audioLevel} />
                  </Button>
                */}

                <Button>
                  <Tooltip title={!canUnMute
                    ? 'You cannot unmute because LiLiCALL does not have access to your microphone'
                    : asPublisher
                      ? 'Mute' 
                      : 'Mute for everyone'
                  }>
                    <span>
                      <MuteCheckbox 
                        audioOn={audioOn} 
                        onMuted={onMuted} 
                        disabled={!canUnMute}
                      />
                    </span>
                  </Tooltip>
                </Button>

                <ConnectivityButton mosGrade={props.mosGrade} />

                {asSubscriber && (
                  <EjectButton userName={userName} onEject={onEject} />
                )}

                {asPublisher && (
                  <Button style={{ minWidth: 0 }} onClick={openSettings}>
                    { settingsOpen ? <SettingsApplications /> : <Settings />}
                  </Button>
                )}

              </ButtonGroup>

            </Box>

            {connectionNotice}

          </Box>
        </Card>
      </Box>

      {asPublisher && (
        <PublisherSettingsModal
          onClose={() => setSettingsOpen(false)}
          open={settingsOpen}
          {...rest}
        />
      )}
    </>
  );
}

export default MeetingAttendeeCard;
