import { getApiUrl } from './ApiUrl';

const baseUrl = getApiUrl();

async function fetchLilicall(path) {
  const url = baseUrl + path;

  return new Promise((resolve, reject) => {
    fetch(url, {
      mode: 'cors',
      cache: 'no-cache',
      // credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
    })
      .then((response) => resolve(response.json()))
      .catch((error) => reject(error));
  });
}

async function postLilicall(path, body = JSON.stringify({})) {
  const url = baseUrl + path;

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      // credentials: 'same-origin',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
    })
      .then((response) => resolve(response.json()))
      .catch((error) => reject(error));
  });
}

async function putLilicall(path, body) {
  const url = baseUrl + path;
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      // credentials: 'same-origin',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
    })
      .then((response) => resolve(response.json()))
      .catch((error) => reject(error));
  });
}

async function delLilicall(path, body) {
  const url = baseUrl + path;
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      // credentials: 'same-origin',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
    })
      .then((response) => resolve(response.json()))
      .catch((error) => reject(error));
  });
}

export {
  fetchLilicall, postLilicall, putLilicall, delLilicall,
};
