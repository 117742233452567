import React from 'react';

import {
  Button,
  Tooltip,
} from '@material-ui/core';

import EjectIcon from '@material-ui/icons/Eject';

const EjectButton = (props) => (
  <Tooltip title="Eject this attendee from the room.">
    <Button onClick={() => props.onEject(props.userName)}>
      <EjectIcon />
    </Button>
  </Tooltip>
);

export default EjectButton;
