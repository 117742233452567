import React, { PureComponent } from 'react';

import AccessDeniedModal from './AccessDeniedModal';
import {connectivityGradeFct} from './ConnectivityButton';
import MeetingAttendeeCard from './MeetingAttendeeCard';
import AudioLevelAverager from '../../lib/AudioLevelAverager';
import RoomDecorator from '../../lib/RoomDecorator';
import OTPublisher from '../OT/OTPublisher';
import PublisherMOS from '../OT/PublisherMOS';

const Resolution = '1280x720';

class Publisher extends PureComponent {

  // static whyDidYouRender = true

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      audioOn: true,
      canUnMute: true,
      audioActive: false,
      audioProcessing: true,
      enableStereo: false,
      publisher: null,
      accessDeniedModalOpen: false,
      // audioDeviceId: 'default', // Do not set audioDeviceId to 'default' because it does not work on Safari
      mosEstimator: null,
      mosGrade: null,
      audioBitrate: 40000,
    };

    this.otPublisher = React.createRef();

    this.audioAverager = new AudioLevelAverager();

    this.publisherEvents = {
      accessDenied: () => {
        console.log('accessDenied');
        this.setState({ 
          accessDeniedModalOpen: true,
          audioOn: false,
          canUnMute: false,
        });
        this.props.onMuteChange(this.getUserName(), false);
      },

      // this will re-render the whole publisher many times ...
      // is there a way to only push the state change to the child component
      // without having to change the state of this current component ?
      audioLevelUpdated: (event) => {
        this.audioAverager.newLevel(event.audioLevel, (error, {shouldUpdate, active, level}) => {
          if (shouldUpdate) {
            this.setState({audioActive: active});
          }
        })
      },

      // Custom, didnt work.

      "signal:userMuted": (event) => {
        console.log("userMuted received on the Publisher");
        console.log(event);

        JSON.parse(event.data);
      }
    }
  }

  getPublisher = () => {
    if (this.state.publisher) return;

    if (this.otPublisher) {
      this.setState({
        publisher: this.otPublisher.current.getPublisher(),
        mosEstimator: PublisherMOS(this.otPublisher.current.getPublisher())
      });
      // give it a second to get stats
      setTimeout(this.watchStats, 1000);
    } else {
      // try later
      setTimeout(this.getPublisher, 5000);
    }
  }

  watchStats = () => {
    if(this.state.mosEstimator) {
      let mos = this.state.mosEstimator.audioScore();
      let newMosGrade = connectivityGradeFct(mos);
      if(this.props.connected && this.state.mosGrade !== newMosGrade){
        console.log(`Publisher#mosGrade changed. Grade: ${newMosGrade} mos: ${mos}`);
        this.setState({mosGrade: newMosGrade})
      }
    }
    if(this.props.connected && this.state.mosGrade !== 'great' && this.state.publisher){
      this.state.publisher.getStats((error, statsArray) => {
        console.log("Publisher#getStats", statsArray);
      })
    }

    // iteration, components unmounts when destroyed
    setTimeout(this.watchStats, 10000);
  }

  onError = (err) => {
    console.error('Error from Publishers', err);
    this.setState({ error: `Failed to publish: ${err.message}` });
    setTimeout(() => this.setState({error: null}), 10000); // clear in 10 seconds
  }

  // // the value is from a select, so it's a deviceId
  // audioDeviceChanged = (audioDeviceId) => {
  //   console.log("Audio Input Device set to: " + audioDeviceId);
  //   // somehow publisher is always null;
  //   // this.state.publisher.setAudioSource(audioDeviceId);
  //   this.setState({audioDeviceId: audioDeviceId});
  // }

  // audioProcessingChanged = (event) => {
  //   console.log("Checked audioProcessing: " + event.target.checked);
  //   this.setState({audioProcessing: (event.target.checked)})
  // }

  // enableStereoChanged = (event) => {
  //   console.log("Checked enableStereo: " + event.target.checked);
  //   this.setState({enableStereo: (event.target.checked)})
  // }

  // audioBitrateChanged = (value) => {
  //   this.setState({audioBitrate: value})
  // }

  onPublisherPropertiesChanged = ({ audioDeviceId, audioProcessing, enableStereo, audioBitrate }) => {
    if (audioDeviceId !== this.state.audioDeviceId ||
      audioProcessing !== this.state.audioProcessing ||
      enableStereo !== this.state.enableStereo ||
      audioBitrate !== this.state.audioBitrate) {
      this.setState({
        audioDeviceId:   audioDeviceId, //  audioDeviceId,
        audioProcessing: audioProcessing, //  event.target.checked,
        enableStereo:    enableStereo, //  event.target.checked,
        audioBitrate:    audioBitrate, //  value
      });
    }
  }

  // helper for common interface
  getUserName() {
    return this.props.userName;
  }

  onMuted = (checked) => {
    console.log("publish audio: " + !checked + " for " + this.getUserName())
    this.setState({ audioOn: !checked });
    this.props.onMuteChange(this.getUserName(), !checked);
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.connected !== prevProps.connected){
      this.getPublisher();
    }

    if(this.props.audioStates.hasOwnProperty(this.getUserName())){
      const remoteAudioState = this.props.audioStates[this.getUserName()];
      if(remoteAudioState !== this.state.audioOn){
        console.log(`Updating mute state of Publisher from remote (from ${this.state.audioOn} to ${remoteAudioState}).`)
        this.setState({ audioOn: remoteAudioState });
      }
    }
  }

  render() {
    const {roomEntity, connected, ...rest} = this.props;

    const roomDeco = new RoomDecorator(roomEntity);
    const userType = roomEntity ? roomDeco.userType(this.props.userEmail) : ''

    return (
      <>
        <MeetingAttendeeCard
          asPublisher={true}
          audioActive={this.state.audioActive}
          audioDeviceId={this.state.audioDeviceId}
          audioLevel={this.state.audioLevel}
          audioBitrate={this.state.audioBitrate}
          audioOn={this.state.audioOn}
          canUnMute={this.state.canUnMute}
          audioProcessing={this.state.audioProcessing}
          enableStereo={this.state.enableStereo}
          onPublisherPropertiesChanged={this.onPublisherPropertiesChanged}
          connectionError={this.state.error}
          disconnect={this.props.disconnect}
          connected={this.props.connected}
          onEject={this.props.onEject}
          onMuted={this.onMuted}
          mosGrade={this.state.mosGrade}
          userType={userType}
          {...rest}
          />

        <AccessDeniedModal 
          open={this.state.accessDeniedModalOpen}
          // onClose={() => this.props.onEject(this.getUserName())}
        />

        <OTPublisher
          properties={{
            audioSource:            this.state.audioDeviceId,
            audioBitrate:           this.state.audioBitrate,
            disableAudioProcessing: !this.state.audioProcessing,
            enableStereo:           this.state.enableStereo,
            insertDefaultUI:        false,
            name:                   this.getUserName(),
            publishAudio:           this.state.audioOn,
            publishVideo:           false,
            showControls:           false,
            targetElement:          null,
            resolution:             Resolution,
            videoSource:            null,
          }}
          onError={this.onError}
          onStreamCreated={this.props.onStreamCreated}
          eventHandlers={this.publisherEvents}
          ref={this.otPublisher}
        />
      </>
    );
  }
}

export default Publisher;
