import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { getApiUrl } from './ApiUrl';


const BASE_URL = getApiUrl();

class LilicallRoomApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: BASE_URL + '/room',
    });
  }

  async listRoomRecordings(roomId: string): Promise<unknown> {
    try {
      const response: AxiosResponse<unknown> = await this.axios.get(`/${roomId}/record`);
      return response.data;
    } catch (err) {
      console.error(`Error retrieving room ${roomId} recordings`, err);
      throw err;
    }
  }

  async reloadRoomRecordings(roomId: string): Promise<unknown> {
    try {
      const response: AxiosResponse<unknown> = await this.axios.put(`/${roomId}/record/reload`);
      return response.data;
    } catch (err) {
      console.error(`Error retrieving room ${roomId} recordings`, err);
      throw err;
    }
  }

  async synchronizeWithLilicast(roomId: string): Promise<unknown> {
    try {
      const response = await this.axios.put(`/${roomId}/callbackSync`);
      return response.data;
    } catch (err) {
      console.error(`Error syncing room ${roomId} with lilicast`, err);
      throw err;
    }
  }

}

export default new LilicallRoomApi();
