import React, { useMemo } from 'react';
import { Container } from '@material-ui/core';

import { MdSync } from 'react-icons/md';

import OutlineButton from '../OutlineButton';
import RecordingList from './recordings/RecordingList';
import { MeetingRoom } from './shapes';

import logoLilicast from '../../assets/logo-lilicast.png';

import './MeetingRoomHeader.scss';

type MeetingRoomProps = {
  room: MeetingRoom,
  readOnly: boolean,
  canRecord: boolean,
  synchronizing: boolean,
  isRecording: boolean,
  onSynchronize: () => void,
};

function MeetingRoomHeader({ 
  room, 
  readOnly, canRecord,
  isRecording = false, 
  synchronizing = false, 
  onSynchronize,
}: MeetingRoomProps) {
  const script = room && room.script;

  const logoUrl = script?.logoUrl ?? logoLilicast;

  const title = script 
    ? script.title 
    : room && room.roomName;

  const isCardRecording = room?.script?.cardRecording ?? room?.cardRecording;

  const roomRecordings = useMemo(
    () => room?.recordings?.filter((rec) => !rec.cardId),
    [ room ],
  );

  return room ? (
    <div className='lc-meeting-room-header'>
      <Container className='lc-meeting-room-header-container'>
        <img src={logoUrl} className='lc-meeting-room-logo' alt='logo' />
        <div className='lc-meeting-room-header-main'>
          <h1 className='lc-meeting-room-title'>{title}</h1>
          {!isCardRecording && (
            <RecordingList 
              recordings={roomRecordings}
              roomId={room.id}
              readOnly={readOnly}
              canRecord={canRecord}
            />
          )}
        </div>

        {!readOnly && (
          <OutlineButton 
            label={room.deleted
              ? 'Room Deleted in LiLiCAST'
              : synchronizing 
                ? 'Synchronizing' 
                : 'Synchronise' 
            }
            icon={<MdSync />}
            className='lc-meeting-room-sync-btn'
            primary={true}
            onClick={onSynchronize}
            disabled={room.deleted || isRecording || synchronizing}
          />
        )}
      </Container>
    </div>
  ) : null;
}

export default MeetingRoomHeader;
