import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { fetchLilicall } from './lib/ApiWrapper';
import MeetingRoomLayout from './components/MeetingRoom/MeetingRoomLayout';
import JoinMeetingRoomSide from './components/MeetingRoom/JoinMeetingRoomSide';
import Goodbye from './components/MeetingRoom/Goodbye';
import { preloadScript } from './components/OT';
import { RecordingContextProvider } from './components/MeetingRoom/recordingContext';
import { SessionContext } from './components/MeetingRoom/sessionContext';

// FIXME: avoid using the api_key in the frontend

function MeetingRoomApp({ userEmail, userName }) {
  const getRoomIdFromUrl = function (href) {
    const match = href.match(/room\/([^?]+)/);
    if (match) {
      return match[1];
    }
    return 'session';
  };

  const roomId = getRoomIdFromUrl(window.location.href.split('?')[0]);

  // State
  const [sessionConfig, setSession] = useState({
    roomId,
    apiKey: null,
    sessionId: null,
    token: null,
    userEmail,
    userName,
  });
  const [sessionLeft, leaveSession] = useState(false);
  const [leaveMessage, setLeaveMessage] = useState('');

  // Functions

  // FIXME: use email to validate the join
  const getSessionToken = async ({ name, email }) => fetchLilicall(`/room/${roomId}/join/${email}?name=${name}`);

  const hasJoined = () => (!!sessionConfig.sessionId);

  const onLeave = useCallback((msg) => {
    if (msg) {
      setLeaveMessage(msg);
    }
    leaveSession(true);
  }, []);

  const handleJoinFormSubmit = useCallback(async (formData, cb) => {
    const errorHandler = (err) => {
      // stop the loading spinner of the form
      // and return the error message.
      cb(err, null);
      console.log(`Error when getting session token: ${err}`);
    };

    try {
      const cred = await getSessionToken(formData);
  
      if (cred.error) {
        return errorHandler(cred.error);
      }
  
      cb(null, cred); // says ok to the form
  
      setSession({
        roomId,
        apiKey: cred.apiKey,
        sessionId: cred.sessionId,
        token: cred.token,
        userEmail: formData.email,
        userName: formData.name,
        userType: cred.userType,
      });
  
      console.log('Session configured.');
    } catch (err) {
      errorHandler(err);
    }
  }, [setSession]);

  const q = new URLSearchParams(window.location.search);
  const qEmail = q.has('email') ? q.get('email') : null;
  const qName = q.has('name') ? q.get('name') : null;

  return !hasJoined()
    ? (
      <JoinMeetingRoomSide
        roomId={roomId}
        onSubmit={handleJoinFormSubmit}
        queryEmail={qEmail}
        queryName={qName}
      />
    )
    : sessionLeft
      ? (
        <Goodbye leaveMessage={leaveMessage} />
      )
      : (
        <RecordingContextProvider>
          <SessionContext.Provider value={sessionConfig}>
            <MeetingRoomLayout
              apiKey={sessionConfig.apiKey}
              sessionId={sessionConfig.sessionId}
              token={sessionConfig.token}

              userEmail={sessionConfig.userEmail}
              userName={sessionConfig.userName}
              userType={sessionConfig.userType}

              onLeave={onLeave}

              roomId={roomId}
            />
          </SessionContext.Provider>
        </RecordingContextProvider>
      );
}

MeetingRoomApp.propTypes = {
  userEmail: PropTypes.string,
  userName:  PropTypes.string,
};

export default preloadScript(MeetingRoomApp);
