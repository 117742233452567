import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SelectAudioDevice from './SelectAudioDevice';

const styles = theme => ({
  fieldset: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bitrateFromGroup: {
    marginTop: theme.spacing(2),
  },
  bitrateBtnGroup: {
    marginTop: theme.spacing(1),
  },
  actions: {
    marginRight: theme.spacing(2),
  },
});

const useStyles = makeStyles(styles);

function PublisherSettings({
  onPublisherPropertiesChanged,
  onCancel,
  ...props
}) {
  const classes = useStyles();

  const [audioBitrate, audioBitrateChanged] = useState(null); // default is auto
  const [enableStereo, enableStereoChanged] = useState(false);
  const [audioProcessing, audioProcessingChanged] = useState(true);
  const [audioDeviceId, audioDeviceChanged] = useState();

  useEffect(() => {
    // get init value from props
    audioBitrateChanged(props.audioBitrate);
    audioProcessingChanged(props.audioProcessing);
    enableStereoChanged(props.enableStereo);
    audioDeviceChanged(props.audioDeviceId);
  }, [props.audioBitrate, props.audioProcessing, props.enableStereo, props.audioDeviceId]);

  const onSubmit = () => {
    const newProp = {
      audioDeviceId,
      audioProcessing,
      enableStereo,
      audioBitrate,
    };
    onPublisherPropertiesChanged(newProp);
    props.onSubmit();
  };

  return (
    <div>
      <DialogContent>
        <FormControl className={classes.fieldset} fullWidth component="fieldset">
          <FormLabel component="legend">Sound options</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={audioProcessing} onChange={(event) => audioProcessingChanged(event.target.checked)} name="audioProcessing" />}
              label="Audio Processing"
            />
            <Typography variant="caption" display="inline" gutterBottom>Gently avoid echoing and noise.</Typography>

            <FormControlLabel
              control={<Switch checked={enableStereo} onChange={(event) => enableStereoChanged(event.target.checked)} name="enableStereo" />}
              label="Enable Stereo"
            />
            <Typography variant="caption" display="inline" gutterBottom>Most mic are mono.</Typography>
          </FormGroup>

          <FormGroup className={classes.bitrateFromGroup}>
            <FormLabel>Bitrate</FormLabel>
            <ButtonGroup className={classes.bitrateBtnGroup} color="primary" aria-label="outlined primary button group">
              <Button onClick={() => audioBitrateChanged(null)} variant={audioBitrate === null ? 'contained' : null}>Auto</Button>
              <Button onClick={() => audioBitrateChanged(12000)} variant={audioBitrate === 12000 ? 'contained' : null}>12k</Button>
              <Button onClick={() => audioBitrateChanged(24000)} variant={audioBitrate === 24000 ? 'contained' : null}>24k</Button>
              <Button onClick={() => audioBitrateChanged(40000)} variant={audioBitrate === 40000 ? 'contained' : null}>40k</Button>
              <Button onClick={() => audioBitrateChanged(60000)} variant={audioBitrate === 60000 ? 'contained' : null}>60k</Button>
            </ButtonGroup>
          </FormGroup>
        </FormControl>

        <FormControl className={classes.fieldset} fullWidth component="fieldset">
          <FormLabel component="legend">Audio input</FormLabel>
          <FormGroup>
            <SelectAudioDevice
              audioDeviceChanged={(devId) => audioDeviceChanged(devId)}
            />
          </FormGroup>
        </FormControl>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Typography variant="caption" display="block" gutterBottom>Applying will disconnect you momentary.</Typography>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={onSubmit} variant="outlined" color="primary">
          Apply
        </Button>
      </DialogActions>

    </div>
  );
}

PublisherSettings.propTypes = {
  audioDeviceId: PropTypes.string,
  audioProcessing: PropTypes.bool,
  enableStereo: PropTypes.bool,
  audioBitrate: PropTypes.number,

  onPublisherPropertiesChanged: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};

export default PublisherSettings;
