// newLevel(level, cb) {
//   this.levelsBuffer.push(level);

//   if(this.first || this.levelsBuffer.length >= this.maxOccurences){
//     cb(null, {shouldUpdate: true, active: active, level: this.bufferBest()})

//     if(this.first){
//       this.first = false;
//     } else {
//       this.clearBuffer();
//     }

//     return;
//   } else {
//     return cb(null, {shouldUpdate: false, active: active, level: this.bufferBest()})
//   }
// }

export default class AudioLevelAverager {
  constructor(maxBuffer) {
    this.levelAverage = 0;
    this.maxOccurences = maxBuffer || 150; // max buffer size
    this.first = true;
    this.levelsBuffer = [];
    this.activeThershold = 0.21;
  }

  newLevel(level, cb) {
    this.levelsBuffer.push(level);

    if (this.levelsBuffer.length > this.maxOccurences) {
      this.levelsBuffer.shift();
    }

    // it should update if the whole buffer has a different state
    // than the previous one
    const active = this.bufferBest() > this.activeThershold;
    const shouldUpdate = this.active !== active;
    this.active = active;

    cb(null, { shouldUpdate, active, level: this.bufferBest() });
  }

  clearBuffer() {
    this.levelsBuffer = [];
  }

  bufferBest() {
    return Math.max(...this.levelsBuffer);
  }

  bufferAverage() {
    let total = 0;
    for (let i = 0; i < this.levelsBuffer.length; i += 1) {
      total += this.levelsBuffer[i];
    }
    const avg = total / this.levelsBuffer.length;
    return avg;
  }
}
