export const getApiUrl = () => {
  const env = process.env.NODE_ENV ?? 'development';
  console.log('getApiUrl - env is', env);

  switch(env.toLowerCase()) {
    case 'production': 
      return '/api';
    case 'development':
      return 'http://localhost:3335/api';
    default:
      console.warn('Unknown env', env);
      return 'http://localhost:3335/api';
  }
};
