import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

import './Loading.scss';

Loading.propTypes = {
  size: PropTypes.number,
};

function Loading({ size = 64 }) {
  return (
    <div className='lc-loading'>
      <CircularProgress size={size} />
    </div>
  );
}

export default Loading;
