import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import PublisherSettings from './PublisherSettings';

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1 / 2),
    top: theme.spacing(1 / 2),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles(styles);

function PublisherSettingsModal(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    onClose,
    ...rest
  } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      fullwidth="true"
      maxWidth="sm"
      onClose={onClose}
      open={props.open}
      aria-labelledby="settings-dialog-title"
    >

      <DialogTitle id="settings-dialog-title" onClose={onClose}>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <PublisherSettings onCancel={onClose} onSubmit={onClose} {...rest} />

    </Dialog>
  );
}

PublisherSettingsModal.propTypes = {
  open: PropTypes.bool,
};

export default PublisherSettingsModal;
