import { useContext, useMemo } from "react";
import RoomDecorator from "../../lib/RoomDecorator";
import { SessionContext } from "./sessionContext";

function useUserType(room) {
  const { userEmail } = useContext(SessionContext);

  const userType = useMemo(
    () => room && new RoomDecorator(room).userType(userEmail),
    [room, userEmail],
  );

  return [ userType ];
}

export default useUserType;
