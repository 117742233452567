import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Tooltip,
} from '@material-ui/core';

import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';

const useStyles = makeStyles(({ palette }) => ({
  signal_low: {
    color: palette.error.main,
  },
  signal_great: {
    color: palette.success.main,
  },
  signal_medium: {
    color: palette.warning.main,
  },
  signal_unknown: {
    color: palette.gray,
  },
}));

export const connectivityGradeFct = (mos) => {
  if (mos === null) { return 'unknown'; }
  if (mos.toString() === 'NaN') { return 'unknown'; }
  if (mos > 3.5) {
    return 'great';
  }
  if (mos > 2) {
    return 'medium';
  }
  return 'low';
};

export default function ConnectivityButton(props) {
  const classes = useStyles();

  return (
    <Button className={classes[`signal_${props.mosGrade}`]}>
      <Tooltip title={`${props.mosGrade} connectivity`}>
        <SignalCellularAltIcon />
      </Tooltip>
    </Button>
  );
}
