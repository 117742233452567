import { gql } from '@apollo/client';

const RecordingFields = `
id
duration
startedOn
url
status
cardId
`;

const ScriptFields = `
id
title
logoUrl
cardRecording
sections {
  id
  title
  cards {
    id
    text
    audioUrl
    audioDuration
    type
  }
}
`;

const RoomFields = `
id
roomId
roomName
cardRecording
fetchScriptUrl
deleted
guests {
  email
  name
}
host {
  email
  name
}
attendees {
  email
  name
}
recordings {
  ${RecordingFields}
}
script {
  ${ScriptFields}
}
`;

export const RoomQuery = gql`
query room($id: String) {
  room(id: $id) {
    ${RoomFields}
    id
    roomId
  }
}
`;

export const RoomRecordingsSync = gql`
mutation roomRecordingsSync($roomId: String!) {
  syncRecordings(roomId: $roomId) {
    id
    recordings {
      ${RecordingFields}
    }
  }
}
`;

export const DeleteRoomRecording = gql`
mutation deleteRoomRecording($roomId: String!, $recordingId: String!) {
  deleteRecording(roomId: $roomId, recordingId: $recordingId) {
    id
    recordings {
      ${RecordingFields}
    }
  }
}
`;
