import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1 / 2),
    top: theme.spacing(1 / 2),
    color: theme.palette.grey[500],
  },
}));

// FIXME: only show to the host.
function AccessDeniedModal(props) {
  const classes = useStyles();

  const [open, setModalOpen] = useState(props.open);

  const closeRecordingsModal = () => {
    setModalOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open]);

  return (
    <Dialog onClose={closeRecordingsModal} aria-labelledby="recordings-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={closeRecordingsModal}>
        You have denied access to the mic....

        <IconButton aria-label="Close" className={classes.closeButton} onClick={closeRecordingsModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <p>
          Please check the permission.
          <br />
          You can still join the room but no one will be able to hear you.
        </p>
      </DialogContent>
    </Dialog>
  );
}

export default AccessDeniedModal;
