import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { MdUnfoldMore, MdUnfoldLess } from 'react-icons/md';
import { Button, Tooltip } from '@material-ui/core';

import EpisodeCardIcon from './Episode.Card.Icon';
import RecordingList from '../recordings/RecordingList';

import { CardShape } from './shapes';
import { MeetingRoomRecordingShape } from '../shapes';

import './Episode.Card.scss';

function EpisodeCard({ roomId, card, recordings, showCardRecordings, canRecord, readOnly }) {
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = useCallback(() => {
    setCollapsed((previousValue) => !previousValue);
  }, [setCollapsed]);

  return (
    <div className={`lc-script-card${collapsed ? ' collapsed' : ''}`}>
      <div className="lc-script-card-header">
        <EpisodeCardIcon cardType={card.type} />
        {showCardRecordings && (
          <RecordingList 
            recordings={recordings}
            extraRecording={card.audioUrl && {
              url: card.audioUrl,
              duration: card.audioDuration,
            }}
            roomId={roomId} cardId={card.id}
            readOnly={readOnly}
            canRecord={canRecord}
          />
        )}
        <Tooltip title={collapsed ? 'Expand card' : 'Collapse card'}>
          <Button className="lc-script-card-collapse-btn" onClick={handleToggleCollapse}>
            {collapsed
              ? <MdUnfoldMore />
              : <MdUnfoldLess />
            }
          </Button>
        </Tooltip>
      </div>
      <div className="lc-script-card-content">
        {card.text}
      </div>
    </div>
  );
}

EpisodeCard.propTypes = {
  roomId: PropTypes.string.isRequired,
  card: CardShape.isRequired,
  recordings: PropTypes.arrayOf(MeetingRoomRecordingShape).isRequired,
  showCardRecordings: PropTypes.bool,
  readOnly: PropTypes.bool,
  canRecord: PropTypes.bool,
};

export default EpisodeCard;
