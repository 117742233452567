import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Tooltip } from '@material-ui/core';
import { MdDelete } from 'react-icons/md';

import AudioPlayer from '../../audio/AudioPlayer';
import EpisodeCardRecordButton from './RecordButton';
import RecordingsDialog from './Recordings.Dialog';

import { DeleteRoomRecording } from '../room.queries';
import { MeetingRoomRecording } from '../shapes';

import './RecordingList.scss';

type EpisodeRecordingProps = {
  roomId: string,
  recording: MeetingRoomRecording,
  readOnly?: boolean,
}

function EpisodeRecording({ roomId, recording, readOnly }: EpisodeRecordingProps) {

  const [ deleteRoomRecording, { loading } ] = useMutation(DeleteRoomRecording, { variables: {
    roomId, recordingId: recording.id,
  }});

  const handleDelete = useCallback(() => deleteRoomRecording(), [deleteRoomRecording]);
  
  if (loading) return null;

  return (
    <div className='lc-recording'>
      {recording.url ? (
        <AudioPlayer url={recording.url} duration={recording.duration} />
      ) : (
        <span className='lc-recording-status'>
          Processing. Please wait...
        </span>
      )}
      {!readOnly && (
        <Tooltip title='Delete Recording'>
          <Button 
            className={`lc-recording-delete-btn ${recording.url ? '' : 'disabled'}`}
            disabled={!recording.url}
            onClick={handleDelete}
          >
            <MdDelete/>
          </Button>
        </Tooltip>
      )}
    </div>
  );
}

type RecordingListProps = {
  recordings: MeetingRoomRecording[],
  extraRecording?: {
    url: string,
    duration: number,
  },
  roomId: string,
  cardId?: string,
  canRecord?: boolean,
  readOnly?: boolean,
  showAll?: boolean,
  showRecord?: boolean,
};

function RecordingList(props: RecordingListProps) {
  const { recordings, extraRecording, roomId, cardId, canRecord = true, readOnly = false, showAll = false, showRecord = true } = props;

  // State

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const openDialog = useCallback(
    () => setDialogOpen(true),
    [setDialogOpen],
  );

  const closeDialog = useCallback(
    () => setDialogOpen(false),
    [setDialogOpen],
  );

  const hasRecordings = recordings.length > 0;
  const recordingNr = recordings.length + (extraRecording ? 1 : 0);

  const [ recording ] = recordings;

  return (
    <>
      <div className='lc-recording-list'>
        {!readOnly && showRecord && (
          <EpisodeCardRecordButton 
            cardId={cardId}
            canRecord={canRecord}
          />
        )}
        {showAll ? (
          recordings.map((recording) => (
            <div key={`recording-${recording.id}`} className='lc-recording-line'>
              <EpisodeRecording roomId={roomId} recording={recording} readOnly={readOnly} />
            </div>
          ))
        ) : (
          recording && (
            <div className='lc-recording-line'>
              <EpisodeRecording roomId={roomId} recording={recording} readOnly={readOnly} />
              {(recordingNr > 1) && (
                <Tooltip title='View all recordings'>
                  <span className='lc-recording-more-link' onClick={openDialog}>{recordingNr - 1} more</span>
                </Tooltip>
              )}
            </div>
          )
        )}
        {}
        {(showAll || !hasRecordings) && extraRecording && (
          <div className={`lc-script-original-recording${(!readOnly && hasRecordings) ? ' has-recordings' : ''}`}>
            <AudioPlayer url={extraRecording.url} duration={extraRecording.duration} />
          </div>
        )}
      </div>

      <RecordingsDialog
        {...props} 
        open={dialogOpen}
        onClose={closeDialog}
      />
    </>
  );
}

export default RecordingList;
