import React from 'react';

import { Box, Grid, Paper } from '@material-ui/core';

function Goodbye({ leaveMessage }) {
  return (
    <Grid
      style={{ flexGrow: '1' }}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Paper elevation={2}>
          <Box m={4} p={4}>
            <h4>See you soon !</h4>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Goodbye;
