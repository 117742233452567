import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/client';

import MeetingRoomApp from './MeetingRoomApp';

import apolloClient from './apolloClient';
import theme from './theme';

import './index.scss';

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <MeetingRoomApp />
    </ThemeProvider>
  </ApolloProvider>,
  document.querySelector('#root'),
);
