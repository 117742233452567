import React, { useCallback, useEffect, useRef } from 'react';
import { MdStop, MdFiberManualRecord } from 'react-icons/md';

import Chrono from '../../Chrono';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import { useRecorder } from '../useRecorder';

import './RecordButton.scss';

type RecordButtonStatusProps = {
  isStarting: boolean,
  isStopping: boolean,
  isSelfRecording: boolean,
  isOtherRecording: boolean,
};

function RecordButtonStatus({isStarting, isStopping, isSelfRecording, isOtherRecording }: RecordButtonStatusProps) {
  if (isStarting) {
    return <span>Starting...</span>;
  }
  
  if (isStopping) {
    return <span>Stopping...</span>;
  }

  if (isOtherRecording) {
    return <span>Other Card Recording</span>;
  }

  if (isSelfRecording) {
    return <span></span>;
  }

  return <span>Record</span>;
}

type RecordButtonProps = {
  cardId?: string,
  canRecord?: boolean,
};

function RecordButton({ cardId, canRecord = true }: RecordButtonProps) {

  const {
    isStarting, isStopping, isSelfRecording, isOtherRecording,
    startRecording, stopRecording,
  } = useRecorder(cardId);

  const handleStartRecording = useCallback(() => {
    if (canRecord) {
      startRecording();
    }
  }, [canRecord, startRecording]);

  const chronoRef = useRef<Chrono | null>(null);

  useEffect(function isSelfRecordingChanged() {
    if (!chronoRef.current) return;

    if(isSelfRecording) {
      chronoRef.current?.start();
    } else {
      chronoRef.current?.stop();
      chronoRef.current?.reset();
    }
  }, [isSelfRecording]);

  const recordTooltipMessage = isOtherRecording 
  ? 'Another card is being recorded' 
  : !canRecord
    ? 'All microphones are muted'
    : 'Start recording';

  return (
    <div className='lc-record-button'>
      {isSelfRecording ? (
        <Tooltip title='Stop Recording'>
          <Button
            className='lc-stop-recording' 
            onClick={stopRecording}
          >
          {(isStarting || isStopping) ? (
            <CircularProgress size={18} />
          ) : (
            <MdStop />
          )}
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title={recordTooltipMessage}>
          <span>
            <Button 
              className='lc-start-recording'
              onClick={handleStartRecording} 
              disabled={isOtherRecording || !canRecord}
            >
              {(isStarting || isStopping) ? (
                <CircularProgress size={18} />
              ) : (
                <MdFiberManualRecord />
              )}
            </Button>
          </span>
        </Tooltip>
      )}
      {isSelfRecording ? (
        <Chrono className='lc-record-timer' ref={chronoRef} />
      ) : (
        <span 
          className={`lc-record-start-recording ${canRecord ? '' : 'disabled'}`}
          onClick={handleStartRecording} 
        >
          <RecordButtonStatus {...{ isStarting, isStopping, isSelfRecording, isOtherRecording }} />
        </span>
      )}
    </div>
  );
}

export default RecordButton;