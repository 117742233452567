import React, { Component } from 'react';
/**
 * React Chronometer
 */
export default class Chrono extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startTS: null,
      diff: null,
      suspended: 0,
      interval: null,
    };

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.reset = this.reset.bind(this);
    this.tick = this.tick.bind(this);
    this.addZero = this.addZero.bind(this);
  }

  start() {
    if (this.state.startTS) {
      // prevent multi clicks on start
      return;
    }
    this.setState((state) => ({
      startTS: +new Date() - state.suspended,
      interval: requestAnimationFrame(this.tick),
      suspended: 0,
    }));
  }

  stop() {
    cancelAnimationFrame(this.state.interval);
    this.setState(state => ({
      startTS: null,
      suspended: +state.diff,
    }));
  }

  reset() {
    cancelAnimationFrame(this.state.interval);
    this.setState({
      startTS: null,
      diff: null,
      suspended: 0,
      interval: null,
    });
  }

  tick() {
    this.setState((state) => ({
      diff: new Date(+new Date() - state.startTS),
      interval: requestAnimationFrame(this.tick),
    }));
  }

  addZero(n) {
    return n < 10 ? `0${n}` : n;
  }

  render() {
    const { diff } = this.state;
    let hundredths = diff ? Math.round(this.state.diff.getMilliseconds() / 10) : 0;
    const seconds = diff ? this.state.diff.getSeconds() : 0;
    const minutes = diff ? this.state.diff.getMinutes() : 0;

    if (hundredths === 100) hundredths = 0;

    const min = this.addZero(minutes);
    const sec = this.addZero(seconds);
    const hund = this.addZero(hundredths);

    const { ...rest } = this.props;

    return (
      <span {...rest}>
        {`${min}:${sec}:${hund}`}
      </span>
    );
  }
}
