import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';

import { useQuery } from '@apollo/client';
import { MdWarning } from 'react-icons/md';
import every from 'lodash/every';

import {
  Box,
  Button,
  Card,
  Container,
  CssBaseline,
  Grid,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { OTSession, OTStreams } from 'opentok-react';

import AutoDisconnectModal from './AutoDisconnectModal';
import ConnectingModal from './ConnectingModal';
import ConnectivityTestModal from './ConnectivityTestModal';
import LiLiCallHeader from './LiLiCallHeader';
import Loading from './Loading';
import MeetingRoomHeader from './MeetingRoomHeader';
import MeetingScript from './MeetingScript';
import Publisher from './Publisher';
import Subscriber from './Subscriber';

import {fetchLilicall} from '../../lib/ApiWrapper';
import LilicallRoomApi from '../../lib/LilicallRoomApi';
import RoomDecorator from '../../lib/RoomDecorator'

import { RecordingContext, setEventedRecordingId, setError as setRecordingError } from './recordingContext';
import { RoomQuery } from './room.queries';

import './MeetingRoomLayout.scss';

function MeetingRoomLayout(props) {

  // Query

  const { data: { room: roomEntity } = {}, loading } = useQuery(RoomQuery, { variables: { id: props.roomId }});

  // State
  
  const [ audioStates, setAudioStates ] = useState({});
  const [ connected, setConnected ] = useState(false);
  const [ disconnectionMessage, setDisconnectionMessage ] = useState("You disconnected yourself.");
  const [ error, setError ] = useState(null);
  const [ openConnectivityTest, setOpenConnectivityTest ] = useState(false);
  const [ reconnecting, setReconnecting ] = useState(false);
  const [ selfDisconnected, setSelfDisconnected ] = useState(false);
  const [ synchronizing, setSynchronizing ] = useState(false);
  const [ snackMessage, setSnackMessage ] = useState(null);

  const allMuted = useMemo(
    function computeAllMuted() {
      if (Object.values(audioStates).length === 0) return false;
      const res = every(Object.values(audioStates), muted => !muted);
      // console.log('allMuted', res);
      return res;
    },
    [audioStates],
  );

  const setStreamHasAudio = useCallback((name, hasAudio) => setAudioStates((audioStates) => ({
    ...audioStates,
    [name]: hasAudio,
  })), [setAudioStates]);

  const unsetStreamHasAudio = useCallback((name) => setAudioStates((audioStates) => {
    const newAudioStates = { ...audioStates };
    delete newAudioStates[name];
    return newAudioStates;
  }), [ setAudioStates]);

  // Context
  const { 
    state: { 
      eventedRecordingId, 
      isRecording: isContextRecording, 
      isFinalising,
      error: recordingError,
    },
    dispatch,
  } = useContext(RecordingContext);

  useEffect(function recordingErrorChanged() {
    if (recordingError) {
      setSnackMessage({
        severity: 'error',
        message: recordingError,
      });
    }
  }, [recordingError]);
  
  const otSessionRef = useRef();

    // https://tokbox.com/developer/sdks/js/reference/Session.html#events
  const sessionEvents = {
    sessionConnected: (event) => {
      console.log('sessionConnected ', event);
      setConnected(true);
    },

    sessionDisconnected: (event) => {
      console.log('XXXXX sessionDisconnected XXXXX');
      console.log(event);

      setConnected(false);
    },

    sessionReconnecting: (event) => {
      console.log('sessionReconnecting', event);
      // console.log(event);

      setConnected(false);
      setReconnecting(true);
    },

    sessionReconnected: (event) => {
      console.log('sessionReconnected', event);
      // console.log(event);

      setConnected(true);
      setReconnecting(false);
    },

    // Connection

    // includes your own ...
    connectionCreated: (event) => {
      console.log('connectionCreated', event);
      // console.log(event);

      // setConnected(true);
    },
    connectionDestroyed: (event) => {
      console.log('connectionDestroyed', event);
      // console.log(event);
      
      // A connection is destroyed for each participant ...
      // So it would be wrong to say we're not connected in that case.
      // setConnected(false);
    },

    // Streams

    // A new stream, published by another client
    // For streams published by your own client, the Publisher object dispatches a streamCreated
    streamCreated: (event) => {
      // otSessionRef.current.subscribe(event.stream, 'subscriber') ?
      console.log('streamCreated', event.stream);
      setStreamHasAudio(event.stream.name, event.stream.hasAudio);
    },
    streamDestroyed: (event) => {
      // otSessionRef.current.subscribe(event.stream, 'subscriber') ?
      console.log('streamDestroyed', event.stream);
      // console.log(event);
    },

    // Archive

    archiveStarted: (event) => {
      console.log('Archive started ' + event.id);
      // console.log(event);
      dispatch(setEventedRecordingId(event.id));
    },
    archiveStopped: (event) => {
      console.log('archiveStopped' + event.id);
      dispatch(setEventedRecordingId(null));
    },

    // Custom

    'signal:userMuted': (event) => {
      const data = JSON.parse(event.data);
      console.log(`Muting ${data.mutedName} by ${data.muterName}`);
      setStreamHasAudio(data.mutedName, data.audio);
    },

    'signal:userEjected': (event) => {
      const data = JSON.parse(event.data);
      console.log(`Ejecting ${data.ejecteeName} by ${data.ejectorName}`);

      // eject myself if this is for me ...
      if (data.ejecteeName === props.userName) {
        leaveRoom(`You've been ejected from the room by ${data.ejectorName}`);
      } else {
        unsetStreamHasAudio(data.ejecteeName);
      }
    }
  };

  useEffect(function loadRoomEntity() {
    if (props.roomId) {
      fetchLilicall(`/room/${props.roomId}`)
        .catch((err) => console.log(`Could not fetch room: ${error}`));
    }
  }, [props.roomId]);

  const timeoutGetStats = () => {
    setTimeout(getStats, 5000);
  };

  const getStats = useCallback(() => {
    if (!otSessionRef.current) return;

    console.log('getStats');
    // this only works on Publisher / Subscriber.
    otSessionRef.current.getStats();
    if(connected) {
      timeoutGetStats();
    }
  }, [connected, timeoutGetStats]);

  // TODO we don't have the email in the streamName
  // this can be used to close the card as well...
  const onSubscriberAdded = useCallback(({userName, userEmail}) => {

  }, []);

  // const openRecordingListModal = useCallback(
  //   () => setRecordingListModalIsOpen(true),
  //   [setRecordingListModalIsOpen],
  // );

  // const closeRecordingListModal = useCallback(
  //   () => setRecordingListModalIsOpen(false),
  //   [setRecordingListModalIsOpen],
  // );

  const handleSynchronizeWithLilicast = useCallback(async () => {
    try {
      setSynchronizing(true);
      await LilicallRoomApi.synchronizeWithLilicast(roomId);
      setSnackMessage({
        severity: 'success',
        message: 'Successfully synchronized with LiLiCAST',
      });
    } catch (err) {
      setSnackMessage({
        severity: 'error',
        message: 'Error synchronizing with LiLiCAST',
      });
    } finally {
      setSynchronizing(false);
    }
  }, [setSnackMessage, setSynchronizing]);

  const handleHideSnackMessage = useCallback(
    () => {
      setSnackMessage(null);
      if (recordingError) {
        dispatch(setRecordingError(undefined));
      }
    },
    [setSnackMessage],
  );

  const onMuteChange = useCallback((mutedName, audioOn) => {
    console.log(`Sending muting signal ${mutedName} by ${props.userName}`);

    const data = JSON.stringify({
      mutedName: mutedName,
      muterName: props.userName,
      audio: audioOn,
    });

    otSessionRef.current.sessionHelper.session.signal({
      type: 'userMuted',
      data: data,
    }, function (error) {
      if (error) {
        console.log('Could not send signal: ' + error.message);
      }
    });
    setStreamHasAudio(mutedName, audioOn);
  }, [setStreamHasAudio]);

  const handleStreamCreated = useCallback((stream) => {
    console.log('handleStreamCreated', stream);
    setStreamHasAudio(stream.name, stream.hasAudio);
  }, [setStreamHasAudio]);

  const onEject = useCallback((ejecteeName) => {
    console.log(`Sending ejecting signal ${ejecteeName} by ${props.userName}`);

    const data = JSON.stringify({
      ejecteeName: ejecteeName,
      ejectorName: props.userName
    })

    otSessionRef.current.sessionHelper.session.signal({
      type: 'userEjected',
      data: data
    }, function (error) {
      if (error) {
        console.log(`Could not send signal: ${error.message}`);
      }
    });
  }, []);

  // when it has been disconnected from the ConnectivityTest
  const onConnectivityTestClose = useCallback(() => {
    console.log("Closing ConnectivityTestModal")
    setOpenConnectivityTest(false);

    // the test seems to disconnect the user ... let's connect again.
    if(!selfDisconnected){
      otSessionRef.current.createSession();
    }
  }, [selfDisconnected, setOpenConnectivityTest]);

  const selfReconnect = useCallback(() => {
    // this will mount the component and connect the user.
    setSelfDisconnected(false);
  }, [setSelfDisconnected]);

  // user initiated disconnect
  const selfDisconnect = useCallback(() => {
    console.log("Self disconnecting.")

    setDisconnectionMessage("You disconnected yourself.");
    setSelfDisconnected(true);

    // this is not being applied by the event handlers
    // cause event handlers are being flushed when unmounted.
    setConnected(false);

    // will disconnect when unmounted
    // otSessionRef.current.destroySession();
  }, [setDisconnectionMessage, setSelfDisconnected, setConnected ]);

  const timeoutDisconnect = useCallback(() => {
    setDisconnectionMessage('You remained inactive for a while and your session expired.');
    setSelfDisconnected(true);
    setConnected(false);
  }, [setDisconnectionMessage, setSelfDisconnected, setConnected]);

  const onError = useCallback((err) => {
    setError(`Failed to connect: ${err.message}`);
  }, [setError]);

  const leaveRoom = useCallback((msg) => {
    // will disconnect when unmounted
    // otSessionRef.current.destroySession();

    props.onLeave(msg);
  }, [props.onLeave]);

  const handleShowConnectivityModal = useCallback(
    () => setOpenConnectivityTest(true),
    [setOpenConnectivityTest],
  );

  const {
    roomId,
  } = props;

  const userType = roomEntity && new RoomDecorator(roomEntity).userType(props.userEmail);

  const isRecording = !!eventedRecordingId;
  const isConnecting = ((!connected || reconnecting) && !selfDisconnected && !openConnectivityTest);

  return loading ? (
    <Loading />
  ) : (
    <>
      <LiLiCallHeader
        isSelfDisconnected={selfDisconnected}
        isRecording={isRecording}
        onCheckConnectivity={handleShowConnectivityModal}
        onReconnect={selfReconnect}
        onDisconnect={selfDisconnect}
        onLeaveRoom={leaveRoom} 
      />

      <MeetingRoomHeader 
        room={roomEntity} 
        readOnly={userType !== 'host'}
        canRecord={!allMuted}
        isRecording={isRecording || isContextRecording || isFinalising}
        synchronizing={synchronizing}
        onSynchronize={handleSynchronizeWithLilicast} 
      />

      <Container component="main" maxWidth="lg" className='lc-app-container'>
        <CssBaseline />

        <AutoDisconnectModal
          trackTime={((connected || reconnecting) && !isRecording) || selfDisconnected}
          onTimeout={timeoutDisconnect}
          recording={isRecording}
        />

        <ConnectivityTestModal
          onClose={onConnectivityTestClose}
          open={openConnectivityTest}
          apiKey={props.apiKey}
          sessionId={props.sessionId}
          token={props.token}
        />

        <ConnectingModal
          open={isConnecting}
          connected={connected}
          reconnecting={reconnecting}
          onDisconnect={selfDisconnect}
        />

        <Grid container justifyContent="flex-start" className='lc-content-container'>
          
          <Grid item xs={12} md={9}>
            <Box alignItems="flex-start">
              {connected && allMuted && !isRecording && (
                <div className='lc-muted-warning'>
                  <MdWarning className='lc-muted-warning-icon' />
                  <span>
                    Every participant of this callroom has muted their microphone. Recording is disabled...
                  </span>
                </div>
              )}  
              <MeetingScript 
                roomEntity={roomEntity}
                canRecord={!allMuted}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={3} className='lc-meeting-participants-container'>
            <Box
              justifyContent="center"
              alignItems="center"
            >
              {selfDisconnected ? (
                <Card elevation={12} >
                  <Box px={2}>
                    <p>
                      {disconnectionMessage}
                      <Button ml={2} onClick={selfReconnect}>
                        Reconnect
                      </Button>
                    </p>
                  </Box>
                </Card>
              ) : (
                <OTSession
                  apiKey={props.apiKey}
                  sessionId={props.sessionId}
                  token={props.token}
                  eventHandlers={sessionEvents}
                  onError={onError}
                  ref={otSessionRef}
                >
                <>
                  <Publisher
                    connected={connected}
                    connectionError={error}
                    userName={props.userName}
                    userEmail={props.userEmail}
                    onMuteChange={onMuteChange}
                    onStreamCreated={handleStreamCreated}
                    onEject={onEject}
                    audioStates={audioStates}
                    roomEntity={roomEntity}
                  />

                  <OTStreams>
                    <Subscriber
                      connected={connected}
                      onMuteChange={onMuteChange}
                      onEject={onEject}
                      audioStates={audioStates}
                      roomEntity={roomEntity}
                      onMounted={onSubscriberAdded}
                    />
                  </OTStreams>
                </>
                </OTSession>
              )}
            </Box>
          </Grid>
        </Grid>

        <Snackbar
          open={!!snackMessage}
          autoHideDuration={15000}
          onClose={handleHideSnackMessage} 
        >
          <Alert className={`lc-alert lc-alert-${snackMessage ? snackMessage.severity : 'info'}`} severity={snackMessage ? snackMessage.severity : 'info'}>
            {snackMessage?.message ?? ''}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default MeetingRoomLayout;
