import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getApiUrl } from './lib/ApiUrl';

const BASE_URL = `${getApiUrl()}/graphql`;

const apolloClient = new ApolloClient({
  uri: BASE_URL,
  cache: new InMemoryCache(),
  credentials: 'include',
  name:        `lilicall-${process.env.NODE_ENV}`,
});

export default apolloClient;
