import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Button,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';

import CallEndIcon from '@material-ui/icons/CallEnd';

const useStyles = makeStyles({

});

export default function ConnectingModal(props) {
  const classes = useStyles();
  const {
    open,
    reconnecting,
    onDisconnect,
  } = props;

  return (
    <div>
      <Dialog open={open} aria-labelledby="modal-connecting">
        <DialogTitle style={{ textAlign: 'center' }}>
          { reconnecting ? 'Reconnecting ...' : 'Connecting'}
        </DialogTitle>
        <LinearProgress style={{ width: '100%' }} color="primary" />
        <DialogContent dividers>
          <Box width={300} display="flex" flexDirection="column" justifyContent="center" alignItems="center">

            { reconnecting ? (
              <Box p={2}>
                <Typography>
                  You have been disconnected... We are reconnecting you!
                </Typography>
              </Box>
            ) : ''}

            <Button
              onClick={onDisconnect}
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<CallEndIcon />}
            >
              Disconnect
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
